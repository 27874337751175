/**
 * @file_purpose  page for Game portfolio
 * @author Swarnali Ghosh
 * @Date_Created 24-01-2023
 * @Date_Modified 25-01-2023
 */
import React, { useEffect } from 'react'
import Footer from '../footer/Footer'
import logo1 from '../../asset/image/bet_big.png';
import logo2 from '../../asset/image/qtech-logo.png';
import logo3 from '../../asset/image/blueprint-gaming-logo.png';
import logo4 from '../../asset/image/dragoon-soft-logo.png';
import logo5 from '../../asset/image/elk-studios-logo.png';
import logo6 from '../../asset/image/ezugi-logo.png';
import logo7 from '../../asset/image/fantasma-games-logo.png';
import logo8 from '../../asset/image/iron-dog-studio-logo.png';
import logo9 from '../../asset/image/kalamba-games-logo.png';
import logo10 from '../../asset/image/l1.png';
import logo11 from '../../asset/image/l2.png';
import logo12 from '../../asset/image/maverick-logo.png';
import logo13 from '../../asset/image/mobilots-logo.png';
import logo14 from '../../asset/image/omi-gaming-logo.png';
import logo15 from '../../asset/image/onetouch-logo.png';
import logo16 from '../../asset/image/playpearls-logo.png';
import logo17 from '../../asset/image/push-gaming-logo.png';
import logo18 from '../../asset/image/quick-spin-logo.png';
import logo19 from '../../asset/image/realtime-gaming-logo.png';
import logo20 from '../../asset/image/revolver-gaming-logo.png';
import logo21 from '../../asset/image/slotmill-logo.png';
import logo22 from '../../asset/image/spearhead-studios-logo.png';
import logo23 from '../../asset/image/thunderkick-provider-logo.png';
import logo24 from '../../asset/image/woohoo-games-logo.png';

import ContactStart from '../contact_start/ContactStart';
import { Link } from 'react-router-dom';

const GamePortfolio = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const Providers = [{
        logo: logo1,
        provider_name: 'Bets2Win'
    },
    {
        logo: logo2,
        provider_name: 'Qtech Gaming'
    },
    {
        logo: logo3,
        provider_name: 'Blueprint Gaming'
    },{
        logo: logo4,
        provider_name: 'Dragoon Soft'
    },{
        logo: logo5,
        provider_name: 'ELK Studios'
    },{
        logo: logo6,
        provider_name: 'Ezugi'
    },{
        logo: logo7,
        provider_name: 'Fantasma'
    },{
        logo: logo8,
        provider_name: 'Irondog'
    },{
        logo: logo9,
        provider_name: 'Kalamba Games'
    },{
        logo: logo10,
        provider_name: 'Pragmatic Play'
    },{
        logo: logo11,
        provider_name: 'Play\'nGo'
    },{
        logo: logo12,
        provider_name: 'Maverick'
    },{
        logo: logo13,
        provider_name: 'Mobilots'
    },{
        logo: logo14,
        provider_name: 'OMI'
    },{
        logo: logo15,
        provider_name: 'OneTouch'
    },{
        logo: logo16,
        provider_name: 'Play Perls'
    },{
        logo: logo17,
        provider_name: 'Push Gaming'
    },{
        logo: logo18,
        provider_name: 'Quickspin'
    },{
        logo: logo19,
        provider_name: 'RTG Slots'
    },{
        logo: logo20,
        provider_name: 'Revolver Gaming'
    },{
        logo: logo21,
        provider_name: 'Slotmill'
    },{
        logo: logo22,
        provider_name: 'Spearhead'
    },{
        logo: logo23,
        provider_name: 'Thunderkick'
    },{
        logo: logo24,
        provider_name: 'Woohoo'
    }

    ];
    
    return (
        <div>

            {/* Game portfolio start */}
            <section className="game_portfolio_sec">
                <div className="container">
                    <div className="row">
                        <div className="game_port_inn">
                            <h4>GAME PORTFOLIO</h4>
                            <h6>DISCOVER 1000+ GAMES FROM 250+ PROVIDERS</h6>
                            {/* <p>Discover our extensive online casino game portfolio, featuring a wide variety of popular and exciting games
                                from <br />top-quality game providers, ensuring a thrilling and immersive gaming experience for all players.
                            </p> */}
                            <p>
                            Explore our diverse collection of online casino games, showcasing an extensive array of thrilling and
popular titles from top-tier game providers. Immerse yourself in an exciting and captivating gaming
experience that caters to all players' preferences
                            </p>
                            <div className="portfolio">

                                {Providers.map(function (data) {
                                    return (
                                        <div className="port_box wow animate__animated animate__pulse">
                                            <div className="port_box_brdr">
                                                {data.provider_name == 'Bets2Win' ? (<Link to="/our-game">
                                                    <img src={data.logo} alt width='213px' />
                                                </Link>):(
                                                    <img src={data.logo} alt width='213px' />
                                                )}
                                                
                                            </div>
                                            <p>{data.provider_name}</p>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Contact start  */}

            <ContactStart />



        </div>
    )
}

export default GamePortfolio
