import React, { useEffect, useState } from 'react'
import pkc from '../asset/image/p1.png'
import respon from '../asset/image/about-us-responsible-gaming.png'
import Acct from '../asset/image/about-us-accout-management.png'
import Mark from '../asset/image/about-us-marketing.png'
import pkc1 from '../asset/image/p2.png'
import pkc2 from '../asset/image/p3.png'
import pkc3 from '../asset/image/p4.png'
import pkc4 from '../asset/image/p5.png'
import pkc5 from '../asset/image/p6.png'
import pkc6 from '../asset/image/p7.png'
import V1 from '../asset/videos/PokerVideo_Short_1.mp4'
import P1 from '../asset/image/poker_poster.png'
import { Link, useLocation } from 'react-router-dom'
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Spinner } from 'react-bootstrap'

function About() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const closeLoaderIn2Seconds = () => {
        setLoading(false)
    }
    useEffect(() => {
        setTimeout(() => {
            closeLoaderIn2Seconds()
        }, 2000);
    }, [])
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
                        {
                loading ?
                    <div className='loader_sec'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>  </div> : ""
            }
            <div className='poker_solution_sec'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="poker_lt">
                                <h4>Committed to creating only the<br /> very best in iGaming</h4>
                                <p>We are the industry leader for engaging slot content. Frequent wins, bonus rounds, excellent RTP and innovative features make every game an immersive experience. Renowned for our stunning graphics, orchestral soundtracks and larger than life characters, all our releases are built with a mobile-first mindset.</p>
                                <p className='pt-0'>Entertainment is our passion. All our games work across genre, theme and skill level whilst packed with features and mechanics; but every release is guaranteed to intrigue and thrill. With series and standalone games plus our in-game promo tool Take the Prize™, we guarantee you the game of your life with It Gaming. Welcome to the players’ choice. Are you ready to win?</p>
                                {/* <NavHashLink to={location.pathname === "" ? '#getStart': '/#getStart'} >
                                    <a className='btn btn-outline-secondary wow animate__animated animate__zoomIn active'>Request A Demo</a>
                                </NavHashLink> */}
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="poker_rt">
                                <img src={pkc} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='service_sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='service_inn'>
                                <h3>Our <span>Services</span></h3>
                                <div className='row'>
                                    <div className="col-sm-7">
                                        <div className="poker_lt">
                                            <h4>Responsible Gaming</h4>
                                            <p>Our clients are the focus of our business. Through our supportive and committed partnership processes and services, working with It guarantees success in gaming across regulated markets.</p>
                                            <p className='pt-0'>It is transparently audited and independently certified in multiple regulatory environments. From start-ups to global brands, we partner with a diverse variety of operators throughout Europe, Scandinavia, LatAm and Africa. We respect the requirements of each jurisdiction that our clients operate within.</p>
                                          
                                        </div>
                                    </div>
                                    <div className="col-sm-5">
                                        <div className="poker_rt">
                                            <img src={respon} alt='' />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='service_inn mar_top desk_on'>
                                <div className='row'>                                    
                                    <div className="col-sm-5">
                                        <div className="poker_rt">
                                            <img src={Acct} alt='' />
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="poker_lt about_rt">
                                            <h4>Account Management</h4>
                                            <p>When you offer award-winning, market leading products, it should be automatic that the Account Management services are outstanding too That is why It Gaming assigns dedicated account managers to each client from the beginning of the contract. Multi-lingual, experienced and committed people – who share their clients’ objectives – work to ensure successful relationship development. Providing in-depth product and market knowledge and understanding of the clients’ business models, all It account managers are trained to ensure optimisation of clients’ casinos. Whether game performance, KPI analysis, bespoke promotions and exclusive access to new releases, your It account manager is working for you.</p>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='service_inn mar_top mob_on'>
                                <div className='row'>                                    
                                    <div className="col-sm-5">
                                        <div className="poker_rt">
                                            <img src={Acct} alt='' />
                                        </div>
                                    </div>
                                    <div className="col-sm-7">
                                        <div className="poker_lt about_rt">
                                            <h4>Account Management</h4>
                                            <p>When you offer award-winning, market leading products, it should be automatic that the Account Management services are outstanding too That is why It Gaming assigns dedicated account managers to each client from the beginning of the contract. Multi-lingual, experienced and committed people – who share their clients’ objectives – work to ensure successful relationship development. Providing in-depth product and market knowledge and understanding of the clients’ business models, all It account managers are trained to ensure optimisation of clients’ casinos. Whether game performance, KPI analysis, bespoke promotions and exclusive access to new releases, your It account manager is working for you.</p>
                                          
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className='service_inn mar_top '>
                                <div className='row'>  
                                <div className="col-sm-7">
                                        <div className="poker_lt ">
                                            <h4>Marketing</h4>
                                            <p>The art of marketing is exactly that – art. Creative, imaginative, compelling and authentic: these are the keys to building campaigns which create awareness and retain a loyal audience.</p>
                                            <p className='pt-0'>It’s specialist marketing team enables casino operators to grow their business through creating communication channels and products that attract, energise engage and retain players for the long-term. Working with a talented design team, It’s omni-channel campaigns deliver. From full artpacks, to display banners, social media campaigns to interviews, events and merchandise, every element is crafted and curated for maximum effect. It works with its clients, affiliates and streamers to produce customised campaigns and specialised assets that resonate across demographics and ensure player buy in. If you want to work with the best, It is the only name you need.

</p>
                                          
                                        </div>
                                    </div>                                  
                                    <div className="col-sm-5">
                                        <div className="poker_rt">
                                            <img src={Mark} alt='' />
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>

                            
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default About