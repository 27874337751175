/**
 * @file_purpose  page for header component
 * @author Swarnali Ghosh
 * @Date_Created 24-01-2023
 * @Date_Modified 25-01-2023
 */
import React, { useState, useRef } from 'react'

import Logo from '../../asset/image/logo.png'
import { Link, useLocation } from 'react-router-dom'
import { HashLink, NavHashLink } from 'react-router-hash-link';
import Navbar from 'react-bootstrap/Navbar';
const Header = () => {
  const location = useLocation();
  const [expanded, setExpanded] = useState(false);
  const toggleRef = useRef(null);
  const [collapseNavLink, setCollapseNavLink] = useState(false)

  return (
    <header className="head_sec">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-xxl-12 col-xl-12 col-lg-12 col-md-12">

            <div className="header_inn">
              <nav className="navbar navbar-expand-lg navbar-light p-0" >
                <div className="container-fluid">
                  <NavHashLink className="navbar-brand" to={location.pathname === "/" ? "#top" : "/#top"}><img src={Logo} alt /></NavHashLink>
                  <button onClick={() => setCollapseNavLink(true)} className={collapseNavLink == false ? "navbar-toggler collapsed" : "navbar-toggler"} ref={toggleRef} type="button" data-bs-toggle="collapse" data-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded={collapseNavLink == false ? false : true} aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"><i className="fa-solid fa-bars" /></span>
                  </button>
                  <div className={collapseNavLink == false ? "collapse navbar-collapse" : "navbar-collapse collapse show"} id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 justify-content-end">
                      <li className="nav-item" >
                        <Link className="nav-link" to="/" onClick={() => setCollapseNavLink(false)}>
                          Home
                        </Link>
                      </li>
                      <li className="nav-item dropdown">
                        <Link className="nav-link" to="our-game/all-tab" >
                          Our Games <i className="fa-solid fa-angle-down" />
                        </Link>
                        <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <Link className="nav-link" to="our-game/slots-tab" onClick={() => setCollapseNavLink(false)}>
                            <li className="dropdown-item">
                              <a href="" className="dropdown-item">
                                Slots
                              </a>
                            </li>
                          </Link>

                          <Link className="nav-link" to="our-game/tableCasino-tab" onClick={() => setCollapseNavLink(false)} >
                            <li className="dropdown-item">
                              <a href="" className="dropdown-item">
                                Table Casinos
                              </a>
                            </li>
                          </Link>
                          <Link className="nav-link" to="our-game/crashGame-tab" onClick={() => setCollapseNavLink(false)} >
                            <li className="dropdown-item">
                              <a href="" className="dropdown-item">
                                Crash Games
                              </a>
                            </li>
                          </Link>
                          <Link className="nav-link" to="our-game/virtualSports-tab" onClick={() => setCollapseNavLink(false)}>
                            <li className="dropdown-item">
                              <a href="" className="dropdown-item">
                                Virtual Sports
                              </a>
                            </li>
                          </Link>

                        </ul>
                      </li>
                      <li>
                        <Link className="nav-link" to="poker" onClick={() => setCollapseNavLink(false)}>
                          Poker Solutions
                        </Link>
                      </li>
                      <li className="nav-item" onClick={() => setCollapseNavLink(false)} >
                        <Link className="nav-link" to="about">
                          About Us
                        </Link>
                      </li>

                      {/* <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" 
                                                // href="solution.html"
                                                 id="navbarDropdown" data-toggle="collapse" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Solutions <i className="fa-solid fa-angle-down" />
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                          
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" to={location.pathname === "/solution" ? "#platform" : "/solution/#platform"} data-toggle="collapse">Platform &amp; Back office</NavHashLink></li>
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" to={location.pathname === "/solution" ? "#agent" : "/solution/#agent"} data-toggle="collapse">Multilevel Agent Management</NavHashLink></li>
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" to={location.pathname === "/solution" ? "#bonus" : "/solution/#bonus"} data-toggle="collapse">Bonus &amp; Loyalty System</NavHashLink></li>
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" to={location.pathname === "/solution" ? "#provider" : "/solution/#provider"} data-toggle="collapse">Payment Providers</NavHashLink></li>
                                                </ul>
                                            </li> 
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" 
                                                // href="#"
                                                 id="navbarDropdown" data-toggle="collapse" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Game Aggregator <i className="fa-solid fa-angle-down" />
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li><Link  onClick={() => toggleRef.current.click()} className="dropdown-item" to="game-portfolio">Game Provider</Link></li>
                                                    <li><Link  onClick={() => toggleRef.current.click()}  className="dropdown-item" to="our-game">Our Games</Link></li>
                                                    <li><Link  onClick={() => toggleRef.current.click()}  className="dropdown-item" to="bets-2-win">Bets2win API</Link></li>
                                                    <li><Link  onClick={() => toggleRef.current.click()} className="dropdown-item" to="poker">Poker Software</Link></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" 
                                                // href="#"
                                                 id="navbarDropdown" data-toggle="collapse" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    Business Models <i className="fa-solid fa-angle-down" />
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" data-toggle="collapse" to={location.pathname === "/business-model" ? "#indi" : "/business-model/#indi"}>Independency Model</NavHashLink></li>
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" to={location.pathname === "/business-model" ? "#white" : "/business-model/#white"}>White label Solution</NavHashLink></li>
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" to={location.pathname === "/business-model" ? "#trunkey" : "/business-model/#trunkey"}>Turnkey solution</NavHashLink></li>
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" to={location.pathname === "/business-model" ? "#iframeapi" : "/business-model/#iframeapi"}>Iframe Api</NavHashLink></li>
                                                    <li><NavHashLink  onClick={() => toggleRef.current.click()} className="dropdown-item" to={location.pathname === "/business-model" ? "#agent" : "/business-model/#agent"}>Affiliate &amp; Agent Model</NavHashLink></li>
                                                </ul>
                                            </li>
                                            <li className="nav-item dropdown">
                                                <NavHashLink 
                                                className="nav-link dropdown-toggle"
                                                to='service'
                                                onClick={() => toggleRef.current.click()}
                                                >
                                                    Service 
                                                  
                                                </NavHashLink>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    <li><Link className="dropdown-item" to="service">24*7 -B2b Support</Link></li>
                                                </ul>
                                            </li>*/}
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>
          {/* <div className="col-sm-12 col-xxl-2 col-xl-2 col-lg-2 col-md-3">
                        <div className="button_sec">
                            <NavHashLink    to={location.pathname === "" ? '#getStart': '/#getStart'} >
                            <button className="btn animate-btn" type="button" value="get started">Client Hub</button>
                            </NavHashLink>
                        </div>
                    </div> */}
        </div>
      </div>
    </header>
  )
}

export default Header
