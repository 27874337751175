/**
 * @file_purpose  page for business model
 * @author Swarnali Ghosh
 * @Date_Created 24-01-2023
 * @Date_Modified 25-01-2023
 */

import React, { useEffect } from 'react'
import ContactStart from '../contact_start/ContactStart'

const BusinessModel = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <>
      {/* middle start */}
      <div>
        <section className="business_sec" id="indi">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="business_inn wow animate__animated animate__zoomIn">
                  <h6>BUSINESS MODEL</h6>
                  <h4>INDEPENDENCY MODEL</h4>
                  <p>Bets2win offers a distinctive and efficient solution known as Independency Model, providing limitless customization possibilities. This solution includes technical documentation and a set of scripts without design elements. Utilizing own resources, you can create your own casino, and our company will assist you throughout the entire process.
                    While you will have access to the source code, it is imperative that you do not employ the source code for alternative purposes without obtaining permission from bets2win.</p>

                  <div class="indepen_side">
                    <div class="row">
                      <div class="col-sm-4">
                        <h5>REQUIREMENT FROM YOUR SIDE</h5>
                        <ul>
                          <li><i class="fa-solid fa-circle-check"></i> DESIGN</li>
                          <li><i class="fa-solid fa-circle-check"></i> FRONT END HTML CODING</li>
                          <li><i class="fa-solid fa-circle-check"></i> TEAM OF IT EXPERTS</li>
                        </ul>
                      </div>
                      <div class="col-sm-8">
                        <div class="indepen_side_rt">
                          <h5>FROM OUR SIDE</h5>
                          <ul>
                            <li><i class="fa-solid fa-circle-check"></i>TECHNICAL KNOWLEDGE TRANSFER</li>
                            <li><i class="fa-solid fa-circle-check"></i> FREE UPDATES</li>
                            <li><i class="fa-solid fa-circle-check"></i> STAGING PLATFORM</li>
                            <li><i class="fa-solid fa-circle-check"></i> PARTIAL SOURCE CODE</li>
                            <li><i class="fa-solid fa-circle-check"></i> BACK OFFICE</li>
                            <li><i class="fa-solid fa-circle-check"></i> DESIGN & DEVELOPMENT ADVISORY</li>


                          </ul>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="white_levelsec" id="white">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="white_level_inn ">
                  <h6>WHITE LABEL SOLUTIONS</h6>
                  <p>Specialize in your strengths – excel in casino marketing and operations. Delegate licensing, payments, and game content integration to your dedicated casino platform provider. This is precisely what the White Label casino software solution provides for iGaming operators.</p>
                  <div className="white_level_inn_dwn">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="white_level_inn_dwn_lt wow animate__animated animate__fadeInLeft">
                          <ul>
                            <li><i className="fa-solid fa-circle-check" /> LIVE PROJECT FROM THE LEADING CASINO PROVIDERS</li>
                            <li><i className="fa-solid fa-circle-check" /> CROSS-PLATFORM SOLUTION</li>
                            <li><i className="fa-solid fa-circle-check" /> MULTICURRENCY</li>
                            <li><i className="fa-solid fa-circle-check" /> SECURE PAYMENT SYSTEM</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="white_level_inn_dwn_lt wl_pad wow animate__animated animate__fadeInRight">
                          <ul>
                            <li><i className="fa-solid fa-circle-check" /> USER-FRIENDLY INTERFACE WITH</li>
                            <li><i className="fa-solid fa-circle-check" /> USER-FRIENDLY INTERFACE</li>
                            <li><i className="fa-solid fa-circle-check" /> AFFILIATE SYSTEM</li>
                            <li><i className="fa-solid fa-circle-check" /> 1000+ GAMES FROM TOP-TIER PROVIDERS</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="trunky_sec" id="trunkey">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="trunky_inn ">
                  <h4>TURNKEY MODEL</h4>
                  <p>Embark on your online casino venture with ease. Our turnkey solutions provide a complete package, offering customizable platforms, a rich assortment of casino games, and streamlined operations. Save time and resources, minimize complexities, and confidently enter the online gaming market—all while significantly cutting costs. From website development to game integration, our comprehensive approach ensures a hassle-free journey towards your online casino success. Experience efficiency, cost-effectiveness, and a quicker path to profitability. Welcome to the world of turnkey solutions, where launching and managing your online casino has never been more straightforward.</p>
                  <div className="trunky_inn_dwn wow animate__animated animate__zoomIn">
                    <ul>
                      <li><i className="fa-solid fa-circle-check" /> PLAYER MANAGEMENT</li>
                      <li><i className="fa-solid fa-circle-check" /> BONUS SYSTEM</li>
                      <li><i className="fa-solid fa-circle-check" /> SECURITY CONTROLS</li>
                      <li><i className="fa-solid fa-circle-check" /> GAME ANALYTICS</li>
                      <li><i className="fa-solid fa-circle-check" /> CUSTOMIZATION OPTIONS</li>
                      <li><i className="fa-solid fa-circle-check" /> FINANCIAL REPORTING</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="iframe_api" id="iframeapi">
          <div className="container">
            <div className="row">
              <div className="col-sm-6">
                <div className="iframe_api_lt wow animate__animated animate__fadeInLeft">
                  <h4>iFRAME API</h4>
                  <p>Incorporating iFrame integration expands the accessibility of the product offering to a broader clientele, whether through API integration or directly launching it on the sports betting platform. Opting for iFrame integration provides a convenient avenue for launching an online betting brand, enhancing product flexibility and enabling clients to tailor solutions to their specific requirements. This integration method opens up greater possibilities for website design, empowering operators to effortlessly implement a variety of creative ideas.</p>
                </div>
              </div>
              <div className="col-sm-2" />
              <div className="col-sm-4">
                <div className="iframe_api_rt wow animate__animated animate__fadeInRight">
                  <ul>
                    <li><i className="fa-solid fa-circle-check" /> RESPONSIVE IFRAME DESIGNS</li>
                    <li><i className="fa-solid fa-circle-check" /> SEAMLESS CUSTOMISATIONS</li>
                    <li><i className="fa-solid fa-circle-check" /> QUICK MARKET TIME</li>
                    <li><i className="fa-solid fa-circle-check" /> EASY INTEGRATIONS</li>
                    <li><i className="fa-solid fa-circle-check" /> ON-TOP SECURITY</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="affilated_sec" id="agent">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="affilated_inn wow animate__animated animate__zoomIn">
                  <h4>AFFILIATE &amp; AGENT MODEL</h4>
                  {/* <p>Gain entry to an extensive affiliate marketing platform tailored for online casinos, providing operators with a diverse set of tools and features to enhance the effectiveness of affiliate marketing campaigns. This platform offers real-time analytics and reporting capabilities, enabling operators to monitor campaign performance and fine-tune strategies for optimal return on investment (ROI). Utilizing advanced segmentation tools, operators can effortlessly focus on specific audiences by delivering personalized promotions and incentives. Additionally, the platform equips operators with robust management tools to efficiently oversee affiliates, track payouts, and ensure compliance with industry regulations.</p> */}
                  <p>
                  Access a comprehensive affiliate marketing platform designed specifically for online casinos, offering
operators a range of tools and features to elevate the success of affiliate marketing campaigns. This
platform provides real-time analytics and reporting functionalities, empowering operators to monitor
campaign performance and refine strategies for maximum return on investment (ROI). Leveraging
advanced segmentation tools, operators can easily target specific audiences by delivering personalized
promotions and incentives. Furthermore, the platform provides operators with robust management tools
to effectively oversee affiliates, track payouts, and ensure compliance with industry regulations.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* Contact start  */}

      <ContactStart />

    </>
  )
}

export default BusinessModel
