/**
 * @file_purpose  page for Not found page
 * @author Swarnali Ghosh
 * @Date_Created 25-01-2023
 * @Date_Modified 25-01-2023
 */
import React from 'react'

const NoPage = () => {
  return (
    <div class="section">
  <h1 class="error">404</h1>
  <div class="page">Ooops!!! The page you are looking for is not found</div>
  <a class="back-home" href="#!">Back to home</a>
</div>
  )
}

export default NoPage
