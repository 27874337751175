/**
 * @file_purpose  page for Bets 2 Win
 * @author Swarnali Ghosh
 * @Date_Created 25-01-2023
 * @Date_Modified 25-01-2023
 */
import React, { useEffect } from 'react'
import ContactStart from '../contact_start/ContactStart'
import { Link,useLocation } from 'react-router-dom'
import { HashLink, NavHashLink } from 'react-router-hash-link';

const Bets2Win = () => {
    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <>
            {/* middle start */}

            <div>
                <section className="betwinapi_sec">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="betwinapi_inn">
                                    <h4>BETS2WIN API</h4>
                                    <p>The integration of Bets2win API games brings numerous advantages to online casino operators. It streamlines the integration process by enabling the incorporation of multiple game providers into a unified platform. This not only saves time and resources but also broadens the array of available games. This becomes particularly crucial for operators aiming to adapt to evolving market trends and cater to diverse player preferences. </p>
                                    <p>The unified API game integration simplifies maintenance and updates, as modifications can be applied to the entire platform at once instead of addressing each game individually. This leads to enhanced overall performance and reduces downtime for players.</p>
                                    <p>Bets2win API integration enhances data analytics and reporting capabilities, empowering operators to gain insights into player behavior and preferences. This information enables informed decision-making to enhance player engagement and retention.</p>
                                    
                                        <NavHashLink to={location.pathname === "" ? '#getStart': '/#getStart'} ><a className="btn btn-outline-secondary wow animate__animated animate__zoomIn" href>Get A Quote</a></NavHashLink>
                                        
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="agent_manage_sec" id="provider">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="agent_manage_lt">
                                    <h4>BENEFITS OF API INTEGRATION</h4>
                                    <p>The Groove API grants online casino operators entry to a broad and diverse portfolio of online casino games, catering to various markets and player preferences.</p>
                                    <p> By utilizing Groove, operators can effortlessly stay abreast of new game releases and updates, ensuring that players consistently have access to the latest and most exceptional titles. The Groove API streamlines the game integration process, saving operators valuable time and resources while expanding the array of games accessible on their platform.</p>
                                    <p> Whether it involves slots, table games, or live dealer games, the Groove API offers a comprehensive selection, establishing itself as an indispensable service for any online casino operator striving to deliver a high-quality gaming experience to their players.</p>
                                    <div className="agent_manage_lt_dwn providers_sec col-sm-6">
                                        <ul>
                                            <li><i className="fa-solid fa-circle-check" /> MORE FASTER -TO-MARKET READY</li>
                                            <li><i className="fa-solid fa-circle-check" /> REDUCED DEVELOPMENT COSTS</li>
                                        </ul>
                                    </div>
                                    <div className="agent_manage_lt_dwn providers_sec col-sm-6">
                                        <ul>
                                            <li><i className="fa-solid fa-circle-check" /> INCREASED GAME VARIETY FOR PLAYERS</li>
                                            <li><i className="fa-solid fa-circle-check" /> EASY TO MAINTENANCE AND UPDATES</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-sm-4">
                                <div className="agent_manage_rt">
                                    <img src alt />
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </div>
            {/* Contact start */}
            <ContactStart/>
        </>
    )
}

export default Bets2Win
