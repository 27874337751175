/**
 * @file_purpose  page for terms of conditions
 * @author Swarnali Ghosh
 * @Date_Created 31-01-2023
 * @Date_Modified 31-01-2023
 */

import React, { useEffect } from 'react'

const TermsOfService = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div>
      
      {/* terms of conditions start */}
      <section className="our_game_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="ourgame_inn">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h4>TERMS OF</h4><h4 style={{ color: "#017cf3" }}>&nbsp;SERVICES</h4>
                </div>
                <p>These Terms and Conditions <b style={{ fontWeight: "900" }}>(“Terms”)</b> govern the access to and use of <b style={{ fontWeight: "900" }}>https://bets2win(“Website”)</b>, except where we expressly state that separate terms apply. These Terms may be updated by us from time to time in accordance with these provisions. You may always view the most recent version of the Terms on the Website. Please do not access or use the Website if you do not agree to the Terms.</p>
              </div>
            </div>
          </div>
        </div>


      </section>

      <div className='service-section' >
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
            <div className='service-section-points'>
          <h6 className='service-section-points-heading'>GENERAL TERMS</h6>
          <p>The Website is made available by Bets2Win and its affiliates (“Bets2Win”, “we”, or “us”).</p>
          <p>The Terms also include our privacy policy, available at [https://www.bets2win.com/privacy-policy/] (“Privacy Policy”) and any guidelines, additional terms, policies, or disclaimers made available or issued and updated by us from time to time, each of which are incorporated by reference in these Terms.</p>
          <p>These Terms constitute a binding and enforceable contract between us and you, an end user of the Website (“you”). You represent and warrant that you have full legal capacity and authority to agree and bind yourself to these Terms.</p>
          <p>You agree and acknowledge that by accessing the Website, you have read, understood, and are bound by the Terms.</p>
          <p>The Terms are subject to revisions at any time, as determined by us, and all changes are effective immediately upon being posted on the Website. It is your responsibility to review these Terms periodically for any updates or changes. You will be deemed to have accepted the changes made to these Terms if you continue to use the Website once the revised Terms have been posted.</p>
        </div>
        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>PURPOSE OF THE WEBSITE</h6>
          <p>We provide video game data to eRetailers, eCasino 0wners in the gaming industry. The data could range from metadata, in-depth game breakdowns using our taxonomy, sentiment analysis, to game content for creating reference fingerprints.</p>
          <p>Please note that the Website contains information about the products and services we offer and is for informational purposes only. Information that is available on or through the Website should not be construed as a commercial offer. The availability of such information does not create any professional relationship between you and us.</p>
          <p>Our products and services are bound by additional terms and conditions that are made available by us from time to time.</p>
        </div>

        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>USER INFORMATION</h6>
          <p>The Website allows you to share your personal data and other information with us (“User Information”). Please note that your User Information may be used by us to engage and interact with you and provide you with our products and services.</p>
          <p>We collect and use your User Information in accordance with our Privacy Policy if such information constitutes personal data or information under applicable laws.</p>
        </div>

        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>USER SUBMISSIONS</h6>
          <p>Our Website may contain interactive features in future or services that allow users who have created an account with us to post, upload, publish, display, transmit, or submit comments, reviews, suggestions, feedback, ideas, or other content on or through the Website</p>
          <p>User Submissions will be treated as non-proprietary and non-confidential. By posting any User Submission, you grant us a royalty-free, perpetual, irrevocable, world-wide, and sub-licensable license to access, copy, modify, adapt, reproduce, redistribute, publish, create derivative works from, compile, arrange, display, disclose, or otherwise make use of User Submissions in any form, media, or technology, whether now known or hereafter developed, alone or as part of other works, for any purpose and in any way without due compensation to you.</p>
          <p>You understand and acknowledge that you are solely responsible for any User Submission you post, and you have full responsibility for such submission, including its legality, reliability, accuracy, and appropriateness. We are not liable for the content or accuracy of any User Submission posted by you or any other user.</p>
        </div>

        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>REPORTING, REVIEWING, AND TAKEDOWN</h6>
          <p>We strive to respond quickly when we receive proper notice of allegations of violations of intellectual property rights and are happy to assist you in any way we reasonably and legally can.</p>
          <p>If you believe any material on our Website infringes your intellectual property rights, please send us a notice and provide us with the following information:</p>
          <ul>
            <li>Your contact details;</li>
            <li>Exact and complete links to the alleged infringing material; and</li>
            <li>Exact and complete links to websites or any other proof of your ownership of the material you allege is being infringed (for example, in the event of an alleged intellectual property rights’ violation, please provide links to your authorized website on which you make your intellectual property available or a registration certificate of your intellectual property).</li>
          </ul>
          <p>We shall review your complaint and may make attempts to restrict access to or take down material if we find that it infringes your rights. We may also make a reasonable attempt to contact the party accused of the alleged infringement and request for clarifications or direct them to cease activities that infringe your rights.</p>
          <p>We will notify you within [21 (Twenty One)] days of the completion of our review. Please note that in accordance with our Privacy Policy, we generally do not share personal information of users registered on our Website.</p>
          <p>Please note that allegations of violations of rights are a serious matter. In the event we find that your claims are frivolous or untrue, we may restrict your access to our Website or impose such other penalty as we deem fit. We may also restrict repeated infringers or those who make frivolous complaints from using our Website.</p>
        </div>

        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>YOUR RESPONSIBILITIES</h6>
          <p>By using the Website, you agree not to:</p>
          <ul>
            <li>Infringe any proprietary rights, including but not limited to copyrights, patents, trademarks, or trade secrets of any party;</li>
            <li>Except as may be provided hereunder, copy, display, distribute, modify, publish, reproduce, store, transmit, post, translate, create any derivative works, rent, or license the Website or any portion thereof;</li>
            <li>Download or copy any kind of information for the benefit of another individual, vendor, or third-party;</li>
            <li>Cache unauthorized hypertext links to the Website;</li>
            <li>Engage in the systematic retrieval of content from the Website to create or compile, directly or indirectly, a collection, compilation, database, or directory;</li>
            <li>Upload, post, or transmit any information through the Website that you do not have a right to make available (such as the intellectual property of another party);</li>
            <li>Upload, post, or transmit any material that contains software viruses or any other computer code, files, or programmes designed to interrupt, destroy, or limit the functionality of any computer software, hardware, or equipment;</li>
            <li>Undertake any action that imposes or may impose, in our sole discretion, an unreasonable or disproportionately large load on our infrastructure;</li>
            <li>Use data mining, robots, or similar data gathering and extraction tools;</li>
            <li>Make any back-up or archival copies of the Website or any part thereof;</li>
            <li>Access, monitor, or copy any information on the Website using any robot, spider, scraper, or other automated means or any manual process for any purpose without our express written permission;</li>
            <li>Violate the restrictions in any robot exclusion headers on the Website or bypass or circumvent other measures employed to prevent or limit access to the Website;</li>
            <li>Deep-link to any portion of the Website (including, without limitation, the purchase path for any service) for any purpose without our express written permission;</li>
            <li>Frame, mirror, or otherwise incorporate any part of the Website into any other website without our prior written authorisation;</li>
            <li>Use the Website in

              <ul>
                <ul>
                  <li>Any unlawful manner,</li>
                  <li>For fraudulent or malicious activities, or</li>
                  <li>In any manner inconsistent with these Terms; and</li>
                </ul>
              </ul>
            </li>
            <li> Violate applicable laws in any manner.</li>
          </ul>
        </div>

        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>INTELLECTUAL PROPERTY RIGHTS</h6>
          <p>The Website contains copyrighted material, trademarks, and other proprietary information that belongs to us or our licensors, and you agree not to use, or otherwise reproduce separately, our proprietary rights from the textual content associated with them without obtaining our prior written consent. You agree to not remove, obscure, or otherwise alter any proprietary notices appearing on any content on the Website, including copyright, trademark, and other intellectual property notices.</p>
          <p>The contents of the Website may not be copied, recopied, reproduced, or otherwise redistributed. You may not copy, display, download, distribute, modify, reproduce, republish, or retransmit any information, text or documents contained in this Website or any portion thereof in any electronic medium or in hard copy, or create any derivative work based on such images, text, or documents, without our express written consent or as otherwise permitted in these Terms.</p>
          <p>You agree that you shall not engage in or use any data mining, robots, scraping, or similar data gathering or extraction methods. If you are blocked by us from accessing the Website (including by blocking your IP address), you agree not to implement any measures to circumvent such blocking (for example, by masking your IP address or using a proxy IP address). Any use of the Website other than as specifically authorised by us is strictly prohibited.</p>
        </div>

        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>THIRD-PARTY WEBSITES OR SERVICES</h6>
          <p>The Website may provide or facilitate, or third-parties may provide, links or access to other sites, services, and resources (“Third-Party Services”). We do not control Third-Party Services and are not responsible for and do not endorse such Third-Party Services. We make no representations and exclude all warranties and liabilities arising out of or pertaining to such Third-Party Services, including their accuracy or completeness.</p>
          <p>You acknowledge and agree that we will not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any content, events, goods, or services available on or through any Third-Party Services.</p>
          <p>Any dealings you have with third-parties while using the Website are between you and the third-party, and you agree that we shall not be liable for any loss or claim that you may have against any such third-party.</p>
          <p>All intellectual property rights in and to Third-Party Services are the property of the respective third-parties.</p>
        </div>

        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>INDEMNITY AND RELEASE</h6>
          <p>You agree to indemnify and hold harmless us, and our affiliates, licensors, and their respective directors, officers, members, managers, employees, and agents from and against any and all claims and expenses arising out of your negligent or unlawful use of the Website or against any claim, demand, lawsuits, judicial proceeding, losses, liabilities, damages and costs (including, without limitation, from all damages, liabilities, settlements, costs and attorneys’ fees), arising out of or relating to these Terms, your use of the Website, a breach of any provision of these Terms by you, or any third-party claim to the extent arising from or connected with an allegation that your use of the Website in accordance with these Terms infringes the rights of a third-party.</p>
        </div>

        <div className='service-section-points'>
          <h6 className='service-section-points-heading'>ASSIGNMENT</h6>
          <p>You shall not license, transfer, or assign the rights, obligations, or covenants under these Terms in any manner without our prior written consent. We may assign our rights to any of our affiliates, subsidiaries, or parent companies, or to any successor in interest of any business associated with our services without any prior notice to you.</p>
        </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TermsOfService
