/**
 * @file_purpose  page for Contact
 * @author Swarnali Ghosh
 * @Date_Created 24-01-2023
 * @Date_Modified 25-01-2023
 */
import React, { useState } from 'react'
import betBig from '../../asset/image/bet_big.png'
import { ToastContainer, toast } from 'react-toastify';
import GeneralService from '../../apis/GeneralService';
import RouteURL from '../../apis/RouteURL';
const ContactStart = () => {
    const [isButtonDisabled, setButtonDisabled] = useState(false);
    const [state, setState] = useState({
        name: "",
        email: "",
        phone: "",
        message: ""
    })

    const [nameErrors, setNameErrors] = useState('');
    const [emailErrors, setEmailErrors] = useState('');
    const [phoneErrors, setPhoneErrors] = useState('');
    const [messageErrors, setMessageErrors] = useState('');


    const handleChange = (e) => {
        const { id, value } = e.target;
        setState(prevState => ({
            ...prevState,
            [id]: value
        }))
    }

        /* Validation Checking */
        const contactValidateForm = (e) => {
            e.preventDefault();
            if (contactHandleValidation()) {
                contactUsSubmit();
            }
            else {
                toast.error("Please fill these fields !", {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        }

            /* Validate all fields */
    const contactHandleValidation = (e) => {
        let nameErrors,emailErrors,phoneErrors,messageErrors;
        let formIsValid = true;

        if (!state.name) {
            formIsValid = false;
            nameErrors = "Name can not be empty";
            setNameErrors(nameErrors)
        } else {
            setNameErrors('');
        }

        if (!state.email) {
            formIsValid = false;
            emailErrors = "Email can not be empty";
            setEmailErrors(emailErrors)
        }else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(state.email))) {
            formIsValid = false;
            emailErrors = "Email is not valid";
            setEmailErrors(emailErrors);
          } else {
            setEmailErrors('');
        }

        if (!state.phone) {
            formIsValid = false;
            phoneErrors = "Contact can not be empty";
            setPhoneErrors(phoneErrors)
        }else if (!(state.phone.length >= 8 && state.phone.length <= 13)) {
            formIsValid = false;
            phoneErrors = "Should be limited to 8-13 integers.";
            setPhoneErrors(phoneErrors);
          } else {
            setPhoneErrors('');
        }

        if (!state.message) {
            formIsValid = false;
            messageErrors = "Contact can not be empty";
            setMessageErrors(messageErrors)
        } else {
            setMessageErrors('');
        }

        return formIsValid;
    }

    async function contactUsSubmit() {
        setButtonDisabled(true);
        // let params = JSON.stringify({
        //     "email": state.email
        // })
        let params = {
            "name": state.name,
            "email": state.email,
            "phone": state.phone,
            "message": state.message

        }

        GeneralService.apiPostCallRequest(RouteURL.signUp, params).then(res => {
            
            setButtonDisabled(false);
            if (res.error === 0) {
                setState({
                    name: "",
                    email: "",
                    phone: "",
                    message: ""
                })
                toast.success(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });

            }else {
                toast.error(res.message, {
                    position: toast.POSITION.BOTTOM_RIGHT
                });
            }
        }).catch(error => {
            
            toast.error(error.message, {
                position: toast.POSITION.BOTTOM_RIGHT
            });
            setButtonDisabled(false);
            if (error.response && error.response.status === 401) {
             
            }
        })

    }

    
    return (
        <div id='getStart'> 
           <ToastContainer />
            <section className="contact_sec" >
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                            <div className="contact_inn_lt wow animate__animated animate__fadeInLeft">
                                <h5>START YOUR <br />iGAMING BUSINESS<br /> WITH US
                                    {/* <img src={betBig} alt /> */}
                                </h5>
                                <p>REACH US FOR MORE INFORMATION</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-xxl-6 col-xl-6 col-lg-6 col-md-12">
                            <div className="contact_inn_rt wow animate__animated animate__zoomIn">
                                <form id="formValidation" name="formValidation" action="valid.html">
                                    <div className="row">
                                        <div className="col-sm-12">
                                            <div className="con_field">
                                                <input type="text" id='name' name='name' placeholder="Your Name" value={state.name} required onChange={handleChange} />
                                                <span style={{ color: "red" }}>{nameErrors} </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="con_field">
                                                <input type="number" placeholder="Your Phone" id='phone' name="phone" value={state.phone} onChange={handleChange} required />
                                                <span style={{ color: "red" }}>{phoneErrors} </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-6">
                                            <div className="con_field">
                                                <input type="email" placeholder="Your Email" id='email' name="email" value={state.email} onChange={handleChange} required />
                                                <span style={{ color: "red" }}>{emailErrors} </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-12">
                                            <div className="con_field">
                                                <textarea placeholder="Your Message" id='message' name="message" value={state.message} onChange={handleChange} required/>
                                                <span style={{ color: "red" }}>{messageErrors} </span>
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12 col-xxl-5 col-xl-5 col-lg-5">
                                            <div className="con_field">
                                                <div className="button_sec">
                                                    <button className="btn btn1" type="button" value="get started" onClick={contactValidateForm} disabled={isButtonDisabled}>Contact Us</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>


    )
}

export default ContactStart
