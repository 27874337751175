/**
 * @file_purpose  page for Privacy Policy
 * @author Swarnali Ghosh
 * @Date_Created 31-01-2023
 * @Date_Modified 31-01-2023
 */

import React, { useEffect } from 'react'

const PrivacyPolicy = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    // privacy policy
    <div>
      <section className="our_game_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="ourgame_inn">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h4>PRIVACY</h4><h4 style={{ color: "#017cf3" }}>&nbsp;POLICY</h4>
                </div>
                <p>Bets2Win’s logo and graphic material are the company’s intellectual property and may not be copied, reproduced, distributed or displayed without written consent.. Under no circumstances may Bets2Win’s intellectual property be displayed in connection with inappropriate or harmful content, including without limitation on websites containing pornographic content or supporting illegal file sharing. Our Privacy Policy explains how we process personal data. Read our Privacy Policy here.</p>
              </div>
            </div>
          </div>
        </div>


      </section>

      <div className='service-section' >
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='service-section-points' >
                <p style={{ textAlign: "center" }}>At Bets2Win, safeguarding your privacy is our top priority. Our privacy policy is designed to ensure the confidentiality and security of your personal information. When you engage with our casino games platform, rest assured that any data collected is handled responsibly and in compliance with industry standards. We only gather essential information necessary for a seamless gaming experience and do not share your data with third parties without your explicit consent. Our commitment to privacy extends to maintaining the confidentiality of your financial transactions and adhering to stringent security measures. By choosing Bets2Win, you entrust us with your gaming enjoyment, and we take every measure to respect and protect your privacy throughout your thrilling casino adventure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='service-section' >
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='service-section-points' >
                <p style={{ textAlign: "center" }}>At Bets2Win, safeguarding your privacy is our top priority. Our privacy policy is designed to ensure the confidentiality and security of your personal information. When you engage with our casino games platform, rest assured that any data collected is handled responsibly and in compliance with industry standards. We only gather essential information necessary for a seamless gaming experience and do not share your data with third parties without your explicit consent. Our commitment to privacy extends to maintaining the confidentiality of your financial transactions and adhering to stringent security measures. By choosing Bets2Win, you entrust us with your gaming enjoyment, and we take every measure to respect and protect your privacy throughout your thrilling casino adventure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='service-section' >
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='service-section-points' >
                <p style={{ textAlign: "center" }}>At Bets2Win, safeguarding your privacy is our top priority. Our privacy policy is designed to ensure the confidentiality and security of your personal information. When you engage with our casino games platform, rest assured that any data collected is handled responsibly and in compliance with industry standards. We only gather essential information necessary for a seamless gaming experience and do not share your data with third parties without your explicit consent. Our commitment to privacy extends to maintaining the confidentiality of your financial transactions and adhering to stringent security measures. By choosing Bets2Win, you entrust us with your gaming enjoyment, and we take every measure to respect and protect your privacy throughout your thrilling casino adventure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='service-section' >
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='service-section-points' >
                <p style={{ textAlign: "center" }}>At Bets2Win, safeguarding your privacy is our top priority. Our privacy policy is designed to ensure the confidentiality and security of your personal information. When you engage with our casino games platform, rest assured that any data collected is handled responsibly and in compliance with industry standards. We only gather essential information necessary for a seamless gaming experience and do not share your data with third parties without your explicit consent. Our commitment to privacy extends to maintaining the confidentiality of your financial transactions and adhering to stringent security measures. By choosing Bets2Win, you entrust us with your gaming enjoyment, and we take every measure to respect and protect your privacy throughout your thrilling casino adventure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='service-section' >
        <div className='container'>
          <div className='row'>
            <div className='col-sm-12'>
              <div className='service-section-points' >
                <p style={{ textAlign: "center" }}>At Bets2Win, safeguarding your privacy is our top priority. Our privacy policy is designed to ensure the confidentiality and security of your personal information. When you engage with our casino games platform, rest assured that any data collected is handled responsibly and in compliance with industry standards. We only gather essential information necessary for a seamless gaming experience and do not share your data with third parties without your explicit consent. Our commitment to privacy extends to maintaining the confidentiality of your financial transactions and adhering to stringent security measures. By choosing Bets2Win, you entrust us with your gaming enjoyment, and we take every measure to respect and protect your privacy throughout your thrilling casino adventure.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PrivacyPolicy
