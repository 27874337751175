import './App.css';
import Solution from './components/solution/Solution';
import '../src/asset/css/animate.css'
import '../src/asset/css/slick-theme.css'
import '../src/asset/css/slick.css'
import '../src/asset/css/style.css'
import OurGame from './components/our_game/OurGame';
import Footer from './components/footer/Footer';
import Home from './components/Home/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from './components/header/Header';
import GamePortfolio from './components/game_portfolio/GamePortfolio';
import BusinessModel from './components/business_model/BusinessModel';
import Service from './components/service/Service';
import NoPage from './components/NoPage/NoPage';
import Bets2Win from './components/bets2win/Bets2Win';
import GameInner from './components/game_inner/GameInner';
import CircusSlot from './components/slot/circus-slots';
import PiratesSlot from './components/slot/pirates-slots';
import BookofHorusSlot from './components/slot/book-of-horus';
import Craps from './components/table/craps';
import Baccarat from './components/table/baccarat';
import Bingo from './components/table/bingo';
import Blackjack from './components/table/blackjack';
import Poker from './components/poker';
import PrivacyPolicy from './components/privacy-policy/PrivacyPolicy';
import TermsOfService from './components/terms-of-services/TermsOfService';
import About from './components/about';

function App() {
  return (
    <BrowserRouter>
    <Header/>
    <Routes>
      <Route path="/" element={<Home />}/>
       <Route path='solution' element={<Solution />} />
       <Route path='game-portfolio' element={<GamePortfolio />} />
       <Route path='game-portfolio/game-inner' element={<GameInner />} />
       <Route path='our-game' element={<OurGame />} />
       <Route path='our-game/:game_type' element={<OurGame />} />
       <Route path='bets-2-win' element={<Bets2Win />} />
       <Route path='business-model' element={<BusinessModel />} />
       <Route path='service' element={<Service />} />
       <Route path='privacy-policy' element={<PrivacyPolicy />} />
       <Route path='terms-of-service' element={<TermsOfService />} />
       <Route path="*" element={<NoPage />} />
       <Route path="/slot/circus-slots" element={<CircusSlot />} />
       <Route path="/slot/pirates-slots" element={<PiratesSlot />} />
       <Route path="/slot/book-of-horus" element={<BookofHorusSlot />} />
       <Route path="/table/craps" element={<Craps />} />
       <Route path="/table/baccarat" element={<Baccarat />} />
       <Route path="/table/bingo" element={<Bingo />} />
       <Route path="/table/blackjack" element={<Blackjack />} />
       <Route path="/poker" element={<Poker />} />
       <Route path="/about" element={<About />} />
      {/* </Route> */}
    </Routes>
    <Footer/>
  </BrowserRouter>
  );
}

export default App;
