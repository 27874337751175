/**
 * @file_purpose  page for Solution
 * @author Swarnali Ghosh
 * @Date_Created 25-01-2023
 * @Date_Modified 25-01-2023
 */

import React from 'react'
import Header from '../header/Header'
import ContactStart from '../contact_start/ContactStart'
import { Route, Link, Routes, useLocation } from 'react-router-dom';
import { HashLink, NavHashLink } from 'react-router-hash-link';
import C1 from '../../asset/image/Group30659.png';
import C2 from '../../asset/image/payment.png';
import C3 from '../../asset/image/Group31628.png';

const Solution = () => {
    const location = useLocation();
    // console.log(location.pathname)
    return (
        <div>
            {/* middle start */}
            <div>
                <section className="solution_mainsec" id="platform">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-6">
                                <div className="casino_lt wow animate__animated animate__fadeInLeft">
                                    <h6>SOLUTIONS</h6>
                                    <h4>CASINO PLATFORM &amp; BACK OFFICE</h4>
                                    <p>The advanced technical infrastructure represents a next-generation, scalable data platform capable of seamless integration with a wide range of third-party solutions. It offers diverse options across various tools, enabling operators to enhance the efficiency of the gambling process while consolidating information on the outcomes of different brands in a centralized location</p>
                                    <NavHashLink to={location.pathname === "" ? '#getStart': '/#getStart'} className="btn btn-outline-secondary wow animate__animated animate__zoomIn" >
                                        Get A Quote
                                    </NavHashLink>
                                    {/* <a className="btn btn-outline-secondary wow animate__animated animate__zoomIn" href>Get A Quote</a> */}
                                </div>
                            </div>
                            <div className="col-sm-6">
                                <div className="casino_rt wow animate__animated animate__fadeInRight">
                                    <ul>
                                        <li><i className="fa-solid fa-circle-check" /> DASHBOARD SUMMARIES</li>
                                        <li><i className="fa-solid fa-circle-check" /> CHURN RATE CALCULATOR</li>
                                        <li><i className="fa-solid fa-circle-check" /> AGENT TRANSACTION MANAGEMENT</li>
                                        <li><i className="fa-solid fa-circle-check" /> REAL-TIME MONITORING</li>
                                        <li><i className="fa-solid fa-circle-check" /> GAME MANAGEMENT</li>
                                        <li><i className="fa-solid fa-circle-check" /> RESPONSIBLE GAMING MANAGEMENT</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="agent_manage_sec" id="agent">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="agent_manage_lt wow animate__animated animate__zoomIn">
                                    <h4>MULTILEVEL AGENT <br />MANAGEMENT</h4>
                                    <p>Efficiently oversee and streamline casino operations with our Multilevel Agent Management feature, ensuring seamless coordination and optimal performance across various levels of the gaming system.</p>
                                    <div className="agent_manage_lt_dwn">
                                        <ul>
                                            <li><i className="fa-solid fa-circle-check" /> STATISTICS CALCULATION</li>
                                            <li><i className="fa-solid fa-circle-check" /> PLAYER TRACKING</li>
                                            <li><i className="fa-solid fa-circle-check" /> PLAYER ACCOUNT MANAGEMENT</li>
                                            <li><i className="fa-solid fa-circle-check" /> QUICK &amp; EASY DATA ACCESS</li>
                                            <li><i className="fa-solid fa-circle-check" /> REPORTING AND ANALYTICS</li>
                                            <li><i className="fa-solid fa-circle-check" /> REAL-TIME ANALYTICS AND REPORTING</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4">
                                <div className="agent_manage_rt wow animate__animated animate__fadeInRight">
                                    <img src={C1} alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="loyalty_sec" id="bonus">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-7">
                                <div className="loyalty_lt wow animate__animated animate__fadeInLeft">
                                <img src={C2} alt />
                                </div>
                            </div>
                            <div className="col-sm-5">
                                <div className="loyalty_rt wow animate__animated animate__fadeInRight">
                                    <h4>BONUS &amp; LOYALTY <br />SYSTEM</h4>
                                    {/* <p>The Bonus System is an independent platform offering Bonus &amp; Loyalty functionalities via an API. This allows for easy integration into any casino, eliminating the necessity for extensive and time-consuming development efforts on your part.</p> */}
                                    <p>The Bonus System stands as an autonomous platform, providing seamless Bonus & Loyalty
functionalities through a user-friendly API. This innovative approach facilitates effortless integration into
any casino, eliminating the need for extensive and time-consuming development efforts on your part.
Embrace efficiency and elevate your gaming platform with our advanced technology</p>
                                    <div className="loyalty_rt_dwn">
                                        <ul>
                                            <li><i className="fa-solid fa-circle-check" /> QUICK &amp; EASY DATA ACCESS</li>
                                            <li><i className="fa-solid fa-circle-check" /> PLAYER ACCOUNT MANAGEMENT</li>
                                            <li><i className="fa-solid fa-circle-check" /> STATISTICS CALCULATION</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="agent_manage_sec" id="provider">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-8">
                                <div className="agent_manage_lt wow animate__animated animate__fadeInLeft">
                                    <h4>PAYMENT PROVIDERS</h4>
                                    {/* <p>Elevate your online casino gaming with a secure payment gateway solution. Seamless transactions &amp; a range of payment options. Protect player information &amp; prevent fraud. Payment gateway are an essential component for any online casino solution. It is vital to the running of an online casino to provide a monitored, secure and user friendly way for players to deposit and withdraw funds from their gaming accounts.</p> */}
                                    <p>Enhance your online casino gaming experience with a reliable payment gateway solution, ensuring
smooth transactions and a variety of payment choices. Safeguard player data and deter fraud. Payment
gateways are a crucial element in every online casino platform, playing a vital role in maintaining a
monitored, secure, and user-friendly method for players to deposit and withdraw funds from their gaming
accounts.</p>
                                    <div className="agent_manage_lt_dwn providers_sec ">
                                        <ul>
                                            <li><i className="fa-solid fa-circle-check" /> ENHANCED PAYMENT PROCESSES</li>
                                            <li><i className="fa-solid fa-circle-check" /> SEAMLESS PAYMENT CONNECTIVITY</li>
                                            <li><i className="fa-solid fa-circle-check" /> FAST AND CUSTOMIZABLE LIMITS</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-sm-4 ">
                                <div className="agent_manage_rt wow animate__animated animate__fadeInRight">
                                    <img src={C3} alt />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>

            {/* Contact start */}
            {/* <ContactStart /> */}
        </div>
    )
}

export default Solution
