/**
 * @file_purpose  page for our game page
 * @author Swarnali Ghosh
 * @Date_Created 24-01-2023
 * @Date_Modified 25-01-2023
 */

import React, { useEffect, useRef } from 'react'
import ContactStart from '../contact_start/ContactStart'
import C1 from '../../asset/image/c1.png'
import C2 from '../../asset/image/c2.png'
import C3 from '../../asset/image/c3.png'
import C4 from '../../asset/image/c4.png'
import C5 from '../../asset/image/c5.png'

import C11 from '../../asset/image/game_thumb/craps_745x420.jpg'
import C12 from '../../asset/image/game_thumb/baccarat_746x420.jpg'
import C13 from '../../asset/image/game_thumb/bingo_745x420.png'
import C14 from '../../asset/image/game_thumb/blackjack_745x420.jpg'
import C15 from '../../asset/image/game_thumb/circus_745x420.jpg'
import V1 from '../../asset/videos/Circus_Slotgame_promo.mp4'
import V3 from '../../asset/videos/Book_Of_Horus_Promo.mp4'
import C16 from '../../asset/image/game_thumb/pirates_745x420.jpg'
import C17 from '../../asset/image/game_thumb/egypt_745x420.jpg'

import Slider from "react-slick";
import { Link,useLocation,useNavigate } from 'react-router-dom'

const CircusSlot = (props) => {
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

    let sliderRef = useRef(null);
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
    
        // responsive: [
        //     {
        //         breakpoint: 1200,
        //         settings: {
        //             dots: true,
        //             infinite: true,
        //             slidesToShow: 7,
        //             slidesToScroll: 1,
        //             autoplay: true,
        //             autoplaySpeed: 2000,
    
        //         }
        //     },
        //     {
        //         breakpoint: 800,
        //         settings: {
        //             dots: true,
        //             slidesToShow: 2,
        //             slidesToScroll: 1,
        //             infinite: true,
        //             autoplay: true,
        //             dots: true
        //         }
        //     },
    
        //     {
        //         breakpoint: 600,
        //         settings: {
        //             dots: true,
        //             slidesToShow: 3,
        //             slidesToScroll: 1,
        //             infinite: true,
        //             autoplay: true,
        //             dots: true
        //         }
        //     },
        //     {
        //         breakpoint: 480,
        //         settings: {
        //             dots: true,
        //             slidesToShow: 2,
        //             slidesToScroll: 1,
        //             infinite: true,
        //             autoplay: true,
        //             dots: true
        //         }
        //     }
    
        // ]
    
    };

  return (
    <div>
      
        <section class="game_main_part mb-5">
            <div class="container mb-5">
                <div class="row">
                <div  className="col-12 back-link d-flex align-items-center mb-5"  onClick={() => navigate(-1)}><i class="fa-solid fa-arrow-left"></i> BACK TO ALL GAMES</div>
                <div class="col-xxl-7 col-xl-7 col-md-12 col-sm-12">
                    <div class="game_part_lt">
                        <Slider {...settings}>
                            <div>
                                <video width='100%' poster={C17} controls autoplay controlsList="nodownload">
                                    <source src={V3} type="video/mp4"/>
                                </video>
                            </div>
                            <div><img src={C17} alt=""/></div>
                        </Slider>
                        
                    </div>
                </div>
                <div class="col-sm-12 col-xxl-5 col-xl-5 col-md-12">
                    <div class="game_part_rt">
                    <h6>BOOK OF HORUS</h6>
                    <div class="game_inn_cat">
                        <p>Game Category <span>Slot</span></p>
                        <p>Game Type  <span>Slot</span></p>
                        <p>RTP <span>94%</span></p>
                        <p>Bet Limits (USD) <span>0.10$ - 10.00$</span></p>
                        <p>Volatility <span>Low- Mid</span></p>
                        <p>Payout Lines <span>Slot</span></p>
                        <div class="button_sec">
                            <Link to="https://games.bets2win.in/SlotBookOfHorus/?mode=offline" target='_blank'>
                                <button class="btn animate-btn" type="button" value="get started">Play Demo Game</button>
                            </Link>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
    </div>
  )
}

export default CircusSlot;
