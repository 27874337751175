/**
 * @file_purpose  page for Home Page
 * @author Swarnali Ghosh
 * @Date_Created 25-01-2023
 * @Date_Modified 30-01-2023
 */
import React, { useEffect, useRef, useState } from 'react'

import V1 from '../../asset/videos/Pirates_SlotGame_Promo_Video_1.mp4'
import V2 from '../../asset/videos/Circus_Slotgame_promo.mp4'
import V3 from '../../asset/videos/Book_Of_Horus_Promo.mp4'
import V4 from '../../asset/videos/Pirates_SlotGame_Promo_Video_1.mp4'
import v1poster from '../../asset/image/game_thumb/pirates_745x420.jpg'
import v2poster from '../../asset/image/game_thumb/circus_745x420.jpg'
import v3poster from '../../asset/image/game_thumb/egypt_745x420.jpg'
import Slot from "../../asset/image/slots.png"
import PokerSoftware from "../../asset/image/poker-software.png"
import TableCasino from "../../asset/image/table-casino.png"
import CrashGame from "../../asset/image/crash-game.png"
import VirtualSports from "../../asset/image/virtual-sports.png"
import PokerImage from "../../asset/image/poker-image.png"
import BookOfHorus from "../../asset/image/game_thumb/egypt_745x420.jpg"
import Pirates from "../../asset/image/game_thumb/pirates_745x420.jpg"
import Baccarat from "../../asset/image/game_thumb/baccarat_746x420.jpg"
import Heroback from "../../asset/image/homepage-hero-bg.jpg"
import pi from "../../asset/image/pirates_745x420.a83737985d4a38138d85.png"
import Slider from "react-slick"
import Spinner from 'react-bootstrap/Spinner';
import "slick-carousel/slick/slick.css";
import { Link, useLocation } from 'react-router-dom'

const videos = [
    // V1,
    // V2,
    // V3
    { src: V1, poster: v1poster },
    { src: V2, poster: v2poster },
    { src: V3, poster: v3poster }
];

const Vid = ({ id, src, poster, play, onVideoEnded }) => {
    const vidRef = useRef(null);
    useEffect(() => {
        const video = vidRef.current;
        const handleVideoEnded = () => {
            // Callback to parent component
            if (typeof onVideoEnded === 'function') {
                onVideoEnded();
            }
        };
        if (play) {
            video.play().catch(error => {
                // Handle error
            });
        } else {
            video.pause();
        }

        video.addEventListener('ended', handleVideoEnded);

        return () => {
            // Cleanup: Remove event listener
            video.removeEventListener('ended', handleVideoEnded);
        };

        // ---------------

        // const playVideo = () => {
        //     if (video && !video.paused) return;
        //     video.play().catch(error => {
        //     // console.error('Failed to play video:', error);
        //     });
        // };
        // const pauseVideo = () => {
        //     if (video && !video.paused) {
        //     video.pause();
        //     }
        // };

        // const handleVideoEnded = () => {
        //     // Pause the video
        //     pauseVideo();

        //     // Callback to parent component
        //     if (typeof onVideoEnded === 'function') {
        //     onVideoEnded();
        //     }
        // };

        // if (play) {
        //     playVideo();
        // } else {
        //     pauseVideo();
        // }
        // video.addEventListener('ended', handleVideoEnded);

        // return () => {
        //     // Cleanup: Pause video and remove event listeners
        //     pauseVideo();
        //     video.removeEventListener('ended', handleVideoEnded);
        // };
    }, [play, onVideoEnded]);


    return (
        <video className={`video-${id}`} ref={vidRef} poster={poster} width='100%' controls={false} muted="mute" controlsList="nodownload">
            <source src={src} type="video/mp4" />
        </video>
    );
};

const Home = () => {
    const sliderRef = useRef(null);
    useEffect(() => { window.scrollTo(0, 0) }, [])
    const [loading, setLoading] = useState(true);

    const [curIdx, setCurIdx] = useState(0);
    const handleVideoEnded = () => {
        // Switch to the next slide
        // setCurIdx(next => (next + 1) % videos.length);
        // console.log('Video ended');
        if (sliderRef.current) {
            sliderRef.current.slickNext();
        }
    };
    const videoSlider = {
        className: "center",
        centerMode: true,
        infinite: true,
        slidesToShow: 1,
        speed: 500,
        dots: true,
        centerPadding: "240px",
        autoplay: false,
        appendDots: dots => <ul>{dots}</ul>,

        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "20px",
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "20px",
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerPadding: "20px",
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    };



    const closeLoaderIn2Seconds = () => {
        setLoading(false)
    }

    useEffect(() => {
        setTimeout(() => {
            closeLoaderIn2Seconds()
        }, 2000);
    }, [])

    return (
        <div>
            {
                loading ?
                    <div className='loader_sec'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>  </div> : ""
            }
            <section className='banner_slide' style={{ backgroundImage: 'url(' + Heroback + ')' }}>
                <div className="mb-5">
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <div className='slider-container'>
                                {/* <Slider ref={sliderRef} {...videoSlider} >
                                    <div >
                                        <div className='video1'>
                                            <video width='100%' controls={false} autoPlay="autoplay" muted="mute" loop={true} controlsList="nodownload">
                                                <source src={V1} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='video1'>
                                            <video width='100%' controls={false} autoPlay="autoplay" muted="mute" loop={true} controlsList="nodownload">
                                                <source src={V2} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='video1'>
                                            <video width='100%' controls={false} autoPlay="autoplay" muted="mute" loop={true} controlsList="nodownload">
                                                <source src={V3} type="video/mp4" />
                                            </video>
                                        </div>
                                    </div>
                                </Slider> */}

                                {/* <Slider {...settings} ref={sliderRef}>
                                    {videos.map((video, index) => (
                                        <div key={index}>
                                            <video controls onEnded={handleVideoEnd}>
                                                <source src={video} type="video/mp4" />
                                                Your browser does not support the video tag.
                                            </video>
                                        </div>
                                    ))}
                                </Slider> */}

                                <Slider ref={sliderRef} {...videoSlider}
                                    beforeChange={(cur, next) => {
                                        setCurIdx(next);
                                    }}
                                >
                                    {videos.map((vid, i) => (
                                        <div key={i} className={i === curIdx ? 'slide active' : 'slide'}>
                                            <div className='video1'>
                                                <div className='overlay'></div>
                                                <Vid id={i} src={vid.src} poster={vid.poster} play={i === curIdx} onVideoEnded={handleVideoEnded} />
                                            </div>
                                        </div>
                                    ))}
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container mt-5">
                    <div className="row">
                        <div className="col-12 text-center mb-4">
                            <h1 className="page_title">
                                ONLINE <span>CASINO GAMES</span> PROVIDER
                            </h1>
                            <p className="sub_heading">
                                Step into the world of endless entertainment with our wide range
                                of engaging casino games. From classic slots to thrilling table
                                & card games, our games are designed to provide an immersive
                                gaming experience for all players
                            </p>
                        </div>
                    </div>
                    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-5 gx-1 gx-sm-5 gx-md-5 gx-lg-5 game-catagory justify-content-center">
                        <div className="col text-center px-3 px-md-4 px-lg-5 mb-4">
                            <Link to="our-game/slots-tab">
                                <div className='online_casino_sec'>
                                    <div className='online_img'>
                                        <img src={Slot} alt="Slot" className="w-100" />
                                    </div>
                                    <h4>Slots</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <p style={{color:"#017CF3", fontSize:14}}>Explore <i class="fa-solid fa-chevron-right"></i></p>
                                </div>
                            </Link>
                        </div>
                        <div className="col text-center px-3 px-md-4 px-lg-5 mb-4">
                            <Link to="our-game/tableCasino-tab">
                                <div className='online_casino_sec'>
                                    <div className='online_img'>
                                        <img src={TableCasino} alt="Slot" className="w-100" />
                                    </div>
                                    <h4>Table Casinos</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <p style={{color:"#017CF3", fontSize:14}}>Explore <i class="fa-solid fa-chevron-right"></i></p>
                                </div>
                            </Link>
                        </div>
                        <div className="col text-center px-3 px-md-4 px-lg-5 mb-4">
                            <Link to="our-game/crashGame-tab">
                                <div className='online_casino_sec'>
                                    <div className='online_img'>
                                        <img src={CrashGame} alt="Slot" className="w-100" />
                                    </div>
                                    <h4>Crash Games</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <p style={{color:"#017CF3", fontSize:14}}>Explore <i class="fa-solid fa-chevron-right"></i></p>
                                </div>
                            </Link>
                        </div>
                        <div className="col text-center px-3 px-md-4 px-lg-5 mb-4">
                            <Link to="our-game/virtualSports-tab">
                                <div className='online_casino_sec'>
                                    <div className='online_img'>
                                        <img src={VirtualSports} alt="Slot" className="w-100" />
                                    </div>
                                    <h4>Virtual Sports</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <p style={{color:"#017CF3", fontSize:14}}>Explore <i class="fa-solid fa-chevron-right"></i></p>
                                </div>
                            </Link>
                        </div>
                        <div className="col text-center px-3 px-md-4 px-lg-5 mb-4">
                            <Link to="poker">
                                <div className='online_casino_sec'>
                                    <div className='online_img'>
                                        <img src={PokerSoftware} alt="Slot" className="w-100" />
                                    </div>
                                    <h4>Poker Software</h4>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
                                    <p style={{color:"#017CF3", fontSize:14}}>Explore <i class="fa-solid fa-chevron-right"></i></p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="heading-two text-center">
                                <h2>LATEST <span>GAMES</span></h2>
                                <p>Embark on your online casino venture with ease. Our solutions provide a complete package, offering customizable platforms, a rich assortment of casino games, and streamlined operations. Save time and resources, minimize complexities, and confidently enter the online gaming market all while significantly cutting costs.</p>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 latest-game-box mb-4">
                            <img src={BookOfHorus} alt="" className='w-100' />
                            <div className='row justify-content-center'>
                                <div className="col-10 col-md-9 col-lg-9">
                                    <h3 className='text-center'>BOOK OF HORUS</h3>
                                    <p className='text-center'>Specialize in your strengths - excel in casino marketing and operations. Delegate licensing, payments, and game content integration to.</p>
                                    <div className="row">
                                        <div className="col-4 col-sm-4">
                                            <p className='gbf-title'>Game Type</p>
                                            <p className='gbf-subtitle'>Slot</p>
                                        </div>
                                        <div className="col-4 col-sm-4 d-flex align-items-center justify-content-center">
                                            <div className='divider'>
                                                <p className='gbf-title'>RTP</p>
                                                <p className='gbf-subtitle'>97.00 %</p>
                                            </div>

                                        </div>
                                        <div className="col-4 col-sm-4 text-end">
                                            <p className='gbf-title'>Release Date</p>
                                            <p className='gbf-subtitle'>17.3.2024</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-12 col-sm-4 latest-game-box mb-4">
                            <img src={Pirates} alt="" className='w-100' />

                            <div className='row justify-content-center'>
                                <div className="col-10 col-md-9 col-lg-9">
                                    <h3 className='text-center'>Pirates Fortuns</h3>
                                    <p className='text-center'>Specialize in your strengths - excel in casino marketing and operations. Delegate licensing, payments, and game content integration to.</p>
                                    <div className="row">
                                        <div className="col-4 col-sm-4">
                                            <p className='gbf-title'>Game Type</p>
                                            <p className='gbf-subtitle'>Slot</p>
                                        </div>
                                        <div className="col-4 col-sm-4 d-flex align-items-center justify-content-center">
                                            <div className='divider'>
                                                <p className='gbf-title'>RTP</p>
                                                <p className='gbf-subtitle'>97.00 %</p>
                                            </div>

                                        </div>
                                        <div className="col-4 col-sm-4 text-end">
                                            <p className='gbf-title'>Release Date</p>
                                            <p className='gbf-subtitle'>17.3.2024</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 latest-game-box mb-4">
                            <img src={Baccarat} alt="" className='w-100' />
                            <div className='row justify-content-center'>
                                <div className="col-10 col-md-9 col-lg-9">
                                    <h3 className='text-center'>Baccarat</h3>
                                    <p className='text-center'>Specialize in your strengths - excel in casino marketing and operations. Delegate licensing, payments, and game content integration to.</p>
                                    <div className="row">
                                        <div className="col-4 col-sm-4">
                                            <p className='gbf-title'>Game Type</p>
                                            <p className='gbf-subtitle'>Slot</p>
                                        </div>
                                        <div className="col-4 col-sm-4 d-flex align-items-center justify-content-center">
                                            <div className='divider'>
                                                <p className='gbf-title'>RTP</p>
                                                <p className='gbf-subtitle'>97.00 %</p>
                                            </div>

                                        </div>
                                        <div className="col-4 col-sm-4 text-end">
                                            <p className='gbf-title'>Release Date</p>
                                            <p className='gbf-subtitle'>17.3.2024</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12 text-center my-5 ">
                            <Link to="our-game/all-tab"><button className='big-button'>View All Games </button></Link>
                        </div>
                    </div>
                </div>

            </section>

            <section className='dark-bg mt-4'>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h2 className='page_title text-white text-center fs-70'><span>POKER SOFTWARE</span> SOLUTION</h2>
                        </div>
                    </div>
                    <div className="row m-0">
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6">
                            <img src={PokerImage} alt="" className='w-100' />
                        </div>
                        <div className="col-12 col-sm-12 col-md-12 col-lg-6 px-lg-5">
                            <p className='sub_heading text-white mb-5'>Revolutionize your poker experience with our cutting-edge software solution. Unleash seamless gameplay, advanced features, and unparalleled user engagement. Our comprehensive back office ensures a smooth transition with effortless onboarding and seamless personalization.</p>
                            <p className='mt-5'>
                                <Link to="poker"><button className='big-button-white mt-3'>Check it Now</button></Link>
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}
export default Home
