import React, { useEffect, useState } from 'react'
import ContactStart from './contact_start/ContactStart'
import pkc from '../asset/image/p1.png'
import pkc1 from '../asset/image/p2.png'
import pkc2 from '../asset/image/p3.png'
import pkc3 from '../asset/image/p4.png'
import pkc4 from '../asset/image/p5.png'
import pkc5 from '../asset/image/p6.png'
import pkc6 from '../asset/image/p7.png'
import V1 from '../asset/videos/PokerVideo_Short_1.mp4'
import P1 from '../asset/image/poker_poster.png'
import { Link, useLocation } from 'react-router-dom'
import { HashLink, NavHashLink } from 'react-router-hash-link';
import { Spinner } from 'react-bootstrap'

function Poker() {
    const location = useLocation();
    const [loading, setLoading] = useState(true);
    const closeLoaderIn2Seconds = () => {
        setLoading(false)
    }
    useEffect(() => {
        setTimeout(() => {
            closeLoaderIn2Seconds()
        }, 2000);
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    return (
        <div>
            {
                loading ?
                    <div className='loader_sec'>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>  </div> : ""
            }

            <div className='poker_solution_sec'>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="poker_lt">
                                <h4>POKER SOFTWARE <br />SOLUTIONS</h4>
                                <p>Are you ready to launch your poker software with cutting-edge online poker solutions? Our fully advanced and highly customizable poker software is the key to meeting all your requirements. Among the top poker game developer, we stand out as the most trusted and proven platform, endorsed by elite businesses. Our comprehensive back-office management ensures that game operators have access to pivotal real-time information.</p>
                                <NavHashLink to={location.pathname === "" ? '#getStart' : '/#getStart'} >
                                    <a className='btn btn-outline-secondary wow animate__animated animate__zoomIn active'>Request A Demo</a>
                                </NavHashLink>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="poker_rt">
                                <img src={pkc} alt='' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="poker_platform_sec">
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <div className='poker_soft_txt'>
                                <h4>SOLUTIONS FOR <span>POKER PLATFORM</span></h4>
                                <p>Start Your Own iGaming Business with a fully customisable poker platform containing Texas Hold'em & 5 Card Omaha. <br />It consists player account management allowing manage player funds, the client software with a range of various games, the back-office with a set of management and analytics tools, and a scalable server.</p>
                                <div className='row'>
                                    <div className='col-sm-4 col-6'>
                                        <div className='poker_soft_box'>
                                            <img src={pkc1} alt='' />
                                            <h6>Texas Hold'em</h6>
                                        </div>
                                    </div>
                                    <div className='col-sm-4 col-6'>
                                        <div className='poker_soft_box'>
                                            <img src={pkc2} alt='' />
                                            <h6>Texas Hold'em</h6>
                                        </div>
                                    </div>
                                    <div className='col-sm-4 col-6'>
                                        <div className='poker_soft_box'>
                                            <img src={pkc3} alt='' />
                                            <h6>Texas Hold'em</h6>
                                        </div>
                                    </div>
                                    <div className='col-sm-4 col-6'>
                                        <div className='poker_soft_box'>
                                            <img src={pkc4} alt='' />
                                            <h6>Anti Fraud System</h6>
                                        </div>
                                    </div>
                                    <div className='col-sm-4 col-6'>
                                        <div className='poker_soft_box'>
                                            <img src={pkc5} alt='' />
                                            <h6>Back Office & CRM</h6>
                                        </div>
                                    </div>
                                    <div className='col-sm-4 col-6'>
                                        <div className='poker_soft_box'>
                                            <img src={pkc6} alt='' />
                                            <h6>Multilevel Agent Management</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='poker_offer'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-sm-12'>
                            <h4 className='what_txt'>WHAT WE OFFER</h4>
                        </div>
                        <div className='col-sm-8'>
                            <div className='poker_offer_lt'>
                                <div>
                                    <video width='100%' poster={P1} controls autoplay controlsList="nodownload">
                                        <source src={V1} type="video/mp4" />
                                    </video>
                                </div>
                            </div>
                        </div>
                        <div className='col-sm-4'>
                            <div class="casino_rt wow animate__animated animate__fadeInRight poker_offer_right">
                                <ul>
                                    <li><i class="fa-solid fa-circle-check"></i> DASHBOARD SUMMARIES</li>
                                    <li><i class="fa-solid fa-circle-check"></i> CHURN RATE CALCULATOR</li>
                                    <li><i class="fa-solid fa-circle-check"></i> AGENT TRANSACTION MANAGEMENT</li>
                                    <li><i class="fa-solid fa-circle-check"></i> REAL-TIME MONITORING</li>
                                    <li><i class="fa-solid fa-circle-check"></i> GAME MANAGEMENT</li>
                                    <li><i class="fa-solid fa-circle-check"></i> RESPONSIBLE GAMING MANAGEMENT</li>
                                </ul>
                                <NavHashLink to={location.pathname === "" ? '#getStart' : '/#getStart'} >
                                    <a className='w-100 btn animate-btn wow animate__animated animate__zoomIn active'>Request A Demo</a>
                                </NavHashLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <ContactStart /> */}
        </div>


    )
}

export default Poker