import API from "./API";

const GeneralService = {
 
    //Post
    apiPostCallRequest: function (url, body = null) {
        return new Promise((resolve, reject) => {
            API.post(`${url}`, body, {
                headers: {
                    //"Access-Control-Allow-origin":"*",
                    //"Access-Control-Allow-Credentials": true,
                    //"Content-Type": "application/json"
                },
            })
                .then((res) => resolve(res.data))
                .catch((error) => reject(error));
        });
    },
 
};
export default GeneralService;
