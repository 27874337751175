/**
 * @file_purpose  page for Game Inner
 * @author Swarnali Ghosh
 * @Date_Created 24-01-2023
 * @Date_Modified 25-01-2023
 */

import React, { useRef } from 'react'
import ContactStart from '../contact_start/ContactStart'
import C1 from '../../asset/image/c1.png'
import C2 from '../../asset/image/c2.png'
import C3 from '../../asset/image/c3.png'
import C4 from '../../asset/image/c4.png'
import C5 from '../../asset/image/c5.png'
import Logo from '../../asset/image/logo.png'
import Betsoft from '../../asset/image/Betsoft-LOGO_on-bright.png'
import Slider from "react-slick";




const GameInner = () => {
    let sliderRef = useRef(null);
    var settings = {
        dots: true,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 2000,
        arrow: false,

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    dots: true,
                    infinite: true,
                    slidesToShow: 7,
                    slidesToScroll: 1,
                    autoplay: true,
                    autoplaySpeed: 2000,

                }
            },
            {
                breakpoint: 800,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    dots: true
                }
            },

            {
                breakpoint: 600,
                settings: {
                    dots: true,
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    dots: true
                }
            },
            {
                breakpoint: 480,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    autoplay: true,
                    dots: true
                }
            }

        ]

    };




    return (
        <div>
            {/* Game inner top start */}
            <section className="game_top_head">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="game_top_logo pt-3 wow animate__animated animate__zoomIn">
                                <img src={Betsoft} alt />
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="game_top_mid">
                                <p>170+ GAMES</p>
                                <p>VARIOUS BONUS ROUNDS</p>
                                <p>MOBILE FIRST</p>
                                <p>A VARIETY OF GAMES</p>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="game_top_rt">
                                <p>WANT TO GET <span style={{ color: '#FD374D' }}>BETSOFT GAMES</span> FOR YOUR CASINO?</p>
                                <a className="btn btn-outline-secondary wow animate__animated animate__zoomIn" href style={{ visibility: 'visible', animationName: 'zoomIn' }}>Get a quote now</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* game category start */}
            <section class="category_sec">
                <div class="container">
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="category_inn">
                                <ul class="nav nav-tabs" id="myTab" role="tablist">
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-tab-pane"
                                            type="button" role="tab" aria-controls="home-tab-pane" aria-selected="true"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path id="categories"
                                                    d="M15.8,8.6v5.6a1.6,1.6,0,0,1-1.6,1.6H8.6A1.6,1.6,0,0,1,7,14.2V8.6A1.6,1.6,0,0,1,8.6,7h5.6A1.6,1.6,0,0,1,15.8,8.6ZM25.4,7H19.8a1.6,1.6,0,0,0-1.6,1.6v5.6a1.6,1.6,0,0,0,1.6,1.6h5.6A1.6,1.6,0,0,0,27,14.2V8.6A1.6,1.6,0,0,0,25.4,7ZM14.2,18.2H8.6A1.6,1.6,0,0,0,7,19.8v5.6A1.6,1.6,0,0,0,8.6,27h5.6a1.6,1.6,0,0,0,1.6-1.6V19.8A1.6,1.6,0,0,0,14.2,18.2Zm8.4,0A4.4,4.4,0,1,0,27,22.6a4.4,4.4,0,0,0-4.4-4.4Z"
                                                    transform="translate(-7 -7)" fill="#747d8c" />
                                            </svg> All Games</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile-tab-pane"
                                            type="button" role="tab" aria-controls="profile-tab-pane" aria-selected="false"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="36.49" height="20" viewBox="0 0 36.49 20">
                                                <g id="slot-machine" transform="translate(0 -117.333)">
                                                    <g id="Group_26352" data-name="Group 26352" transform="translate(0 123.673)">
                                                        <g id="Group_26351" data-name="Group 26351">
                                                            <path id="Path_26890" data-name="Path 26890"
                                                                d="M26.561,202.667H2.277A2.279,2.279,0,0,0,0,204.944v9.107a2.279,2.279,0,0,0,2.277,2.277H26.561a2.279,2.279,0,0,0,2.277-2.277v-9.107A2.279,2.279,0,0,0,26.561,202.667ZM7.5,206.809a29.6,29.6,0,0,0-2.067,4.99.747.747,0,0,1,.634.733.759.759,0,0,1-.759.759H3.794a.759.759,0,0,1,0-1.518h.1A23.567,23.567,0,0,1,5.61,207.22H4.553a.759.759,0,0,1-1.518,0v-.759a.759.759,0,0,1,.759-.759H6.83a.758.758,0,0,1,.674,1.106Zm3.121,7.241a.759.759,0,1,1-1.518,0v-9.107a.759.759,0,1,1,1.518,0v9.107Zm5.986-7.241a29.594,29.594,0,0,0-2.067,4.99.747.747,0,0,1,.634.733.759.759,0,0,1-.759.759H12.9a.759.759,0,0,1,0-1.518H13a23.567,23.567,0,0,1,1.718-4.553H13.66a.759.759,0,0,1-1.518,0v-.759a.759.759,0,0,1,.759-.759h3.036a.758.758,0,0,1,.674,1.106Zm3.121,7.241a.759.759,0,1,1-1.518,0v-9.107a.759.759,0,1,1,1.518,0v9.107Zm5.986-7.241a29.594,29.594,0,0,0-2.067,4.99.747.747,0,0,1,.634.733.759.759,0,0,1-.759.759H22.008a.759.759,0,0,1,0-1.518h.1a23.567,23.567,0,0,1,1.718-4.553H22.767a.759.759,0,0,1-1.518,0v-.759a.759.759,0,0,1,.759-.759h3.036a.758.758,0,0,1,.674,1.106Z"
                                                                transform="translate(0 -202.667)" fill="#747d8c" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_26354" data-name="Group 26354" transform="translate(30.846 120.458)">
                                                        <g id="Group_26353" data-name="Group 26353">
                                                            <path id="Path_26891" data-name="Path 26891"
                                                                d="M430.461,160a.759.759,0,0,0-.759.759v8.348h-2.277a.759.759,0,0,0,0,1.518h3.036a.759.759,0,0,0,.759-.759v-9.107A.759.759,0,0,0,430.461,160Z"
                                                                transform="translate(-426.666 -160)" fill="#747d8c" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_26356" data-name="Group 26356" transform="translate(32.49 117.333)">
                                                        <g id="Group_26355" data-name="Group 26355" transform="translate(0 0)">
                                                            <ellipse id="Ellipse_2" data-name="Ellipse 2" cx="2" cy="2.5" rx="2" ry="2.5"
                                                                fill="#747d8c" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_26358" data-name="Group 26358" transform="translate(27.709 125.191)">
                                                        <g id="Group_26357" data-name="Group 26357">
                                                            <path id="Path_26892" data-name="Path 26892"
                                                                d="M384.759,224a.759.759,0,0,0,0,1.518v7.589a.759.759,0,0,0,0,1.518h.759V224Z"
                                                                transform="translate(-384 -224)" fill="#747d8c" />
                                                        </g>
                                                    </g>
                                                    <g id="Group_26360" data-name="Group 26360" transform="translate(30.846 125.33)">
                                                        <g id="Group_26359" data-name="Group 26359">
                                                            <path id="Path_26893" data-name="Path 26893"
                                                                d="M426.667,225.963v10.345a2.273,2.273,0,0,0,1.518-2.137V228.1A2.273,2.273,0,0,0,426.667,225.963Z"
                                                                transform="translate(-426.667 -225.963)" fill="#747d8c" />
                                                        </g>
                                                    </g>
                                                </g>
                                            </svg> Slots</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                                            type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="23.128" height="20" viewBox="0 0 23.128 20">
                                                <g id="sport-mode" transform="translate(0 -34.612)">
                                                    <path id="Path_26900" data-name="Path 26900"
                                                        d="M15.269,230.236,9.2,224.163.448,232.91a1.533,1.533,0,1,0,2.168,2.168L9.2,228.5l2.822,2.822-1.573,1.573a1.533,1.533,0,1,0,2.168,2.168l2.657-2.657a1.533,1.533,0,0,0,0-2.168Z"
                                                        transform="translate(0 -180.914)" fill="#747d8c" />
                                                    <path id="Path_26901" data-name="Path 26901"
                                                        d="M118.418,38.312l1.155,1.155,1.084-1.084a2.943,2.943,0,0,1,1.385-.782l-2.54-2.54a1.533,1.533,0,0,0-2.168,0L114.3,38.092a1.533,1.533,0,0,0,2.168,2.168Z"
                                                        transform="translate(-108.693 0)" fill="#747d8c" />
                                                    <path id="Path_26902" data-name="Path 26902"
                                                        d="M3.368,158.36H.674a.674.674,0,1,1,0-1.347H3.368a.674.674,0,1,1,0,1.347Z"
                                                        transform="translate(0 -116.868)" fill="#747d8c" />
                                                    <path id="Path_26903" data-name="Path 26903"
                                                        d="M21.779,244.257h-.007l-3.1-.032a.674.674,0,1,1,.014-1.347l3.1.032a.674.674,0,0,1-.007,1.347Z"
                                                        transform="translate(-17.192 -198.85)" fill="#747d8c" />
                                                    <path id="Path_26904" data-name="Path 26904"
                                                        d="M33.765,72.5H31.674a.674.674,0,1,1,0-1.347h2.091a.674.674,0,1,1,0,1.347Z"
                                                        transform="translate(-29.608 -34.896)" fill="#747d8c" />
                                                    <path id="Path_26905" data-name="Path 26905"
                                                        d="M239.669,40.773a1.533,1.533,0,0,0-2.168,0l-1.947,1.948-3.031-3.031a2.659,2.659,0,1,0-.915-.671,1.532,1.532,0,0,0-1.546.378l-2.719,2.717,3.515,3.515,1.635-1.633h0l1.977,1.977a1.533,1.533,0,0,0,2.168,0l3.031-3.031a1.533,1.533,0,0,0,0-2.168Z"
                                                        transform="translate(-216.99 -0.001)" fill="#747d8c" />
                                                </g>
                                            </svg> Virtual Sports</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                                            type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"><svg id="spade-token"
                                                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <path id="Path_26894" data-name="Path 26894"
                                                    d="M10,0A10,10,0,1,0,20,10,10.011,10.011,0,0,0,10,0ZM9.2.779h1.551V2.33H9.2ZM16.984,10A6.984,6.984,0,1,1,10,3.016,6.992,6.992,0,0,1,16.984,10ZM4.028,2.931l1.1,1.1-1.1,1.1-1.1-1.1ZM2.3,10.776H.75V9.224H2.3Zm1.727,6.293-1.1-1.1,1.1-1.1,1.1,1.1Zm6.719,2.152H9.2V17.67h1.551Zm5.225-2.152-1.1-1.1,1.1-1.1,1.1,1.1Zm0-11.944-1.1-1.1,1.1-1.1,1.1,1.1Zm1.669,5.651V9.224h1.551v1.551Z"
                                                    fill="#747d8c" />
                                                <path id="Path_26895" data-name="Path 26895"
                                                    d="M50,55.657A5.659,5.659,0,1,0,55.657,50,5.665,5.665,0,0,0,50,55.657Zm8.364-.48a2.272,2.272,0,0,1,.353,1.279A1.645,1.645,0,0,1,57.007,58.2a1.414,1.414,0,0,1-1.166-.537l.329,1.165H55.135l.322-1.138a1.429,1.429,0,0,1-1.144.533A1.658,1.658,0,0,1,52.6,56.467a2.306,2.306,0,0,1,.351-1.29,23.575,23.575,0,0,1,2.714-2.626A23.689,23.689,0,0,1,58.363,55.177Z"
                                                    transform="translate(-45.657 -45.657)" fill="#747d8c" />
                                            </svg> Casino</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                                            type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="34.041" height="20" viewBox="0 0 34.041 20">
                                                <g id="Group_26365" data-name="Group 26365" transform="translate(-1001.979 -182)">
                                                    <g id="video-camera" transform="translate(1001.979 85.488)">
                                                        <g id="Group_26362" data-name="Group 26362" transform="translate(0 96.512)">
                                                            <g id="Group_26361" data-name="Group 26361" transform="translate(0 0)">
                                                                <path id="Path_26898" data-name="Path 26898"
                                                                    d="M19.255,96.512H3.724A3.735,3.735,0,0,0,0,100.236v12.551a3.735,3.735,0,0,0,3.724,3.724H19.255a3.735,3.735,0,0,0,3.724-3.724V100.236A3.711,3.711,0,0,0,19.255,96.512Z"
                                                                    transform="translate(0 -96.512)" fill="#747d8c" />
                                                            </g>
                                                        </g>
                                                        <g id="Group_26364" data-name="Group 26364" transform="translate(24.842 98.5)">
                                                            <g id="Group_26363" data-name="Group 26363">
                                                                <path id="Path_26899" data-name="Path 26899"
                                                                    d="M347.947,123.907a1.9,1.9,0,0,0-.633.261l-5.81,3.352v8.641l5.847,3.352a2.214,2.214,0,0,0,3.054-.819,2.276,2.276,0,0,0,.3-1.117V126.067A2.242,2.242,0,0,0,347.947,123.907Z"
                                                                    transform="translate(-341.504 -123.847)" fill="#747d8c" />
                                                            </g>
                                                        </g>
                                                    </g>
                                                    <circle id="Ellipse_3" data-name="Ellipse 3" cx="3.5" cy="3.5" r="3.5"
                                                        transform="translate(1015 184)" fill="#747d8c" />
                                                </g>
                                            </svg> Live Casino</button>
                                    </li>
                                    <li class="nav-item" role="presentation">
                                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact-tab-pane"
                                            type="button" role="tab" aria-controls="contact-tab-pane" aria-selected="false"><svg
                                                xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
                                                <g id="poker-cards" transform="translate(0 0)">
                                                    <path id="Path_26896" data-name="Path 26896"
                                                        d="M247.18,1.34,239.713.023a1.544,1.544,0,0,0-1.789,1.253l-.071.4a2.707,2.707,0,0,1,2.279,1.983l.456,1.7a1.392,1.392,0,0,1,1.88.86,1.392,1.392,0,0,1,2.476,1.088c-.29,1.645-2.055,2.967-2.893,3.511l1.279,4.773a2.692,2.692,0,0,1,.091.765l1.076.19a1.544,1.544,0,0,0,1.789-1.253l2.146-12.169A1.544,1.544,0,0,0,247.18,1.34Z"
                                                        transform="translate(-228.457 0)" fill="#747d8c" />
                                                    <path id="Path_26897" data-name="Path 26897"
                                                        d="M10.36,73.938A1.548,1.548,0,0,0,8.869,72.78a1.53,1.53,0,0,0-.4.054L1.145,74.819A1.564,1.564,0,0,0,.053,76.732l3.2,12.075a1.548,1.548,0,0,0,1.491,1.158,1.529,1.529,0,0,0,.4-.054l7.324-1.985a1.564,1.564,0,0,0,1.092-1.913Zm-5.7,8.254a.418.418,0,0,1-.118-.444l1.12-3.355a.408.408,0,0,1,.659-.179L8.95,80.553A.418.418,0,0,1,9.067,81l-1.12,3.355a.408.408,0,0,1-.659.179Z"
                                                        transform="translate(0 -69.965)" fill="#747d8c" />
                                                </g>
                                            </svg> Table</button>
                                    </li>

                                </ul>
                                <div class="tab-content" id="myTabContent">
                                    <div class="tab-pane fade show active" id="home-tab-pane" role="tabpanel" aria-labelledby="home-tab"
                                        tabindex="0">
                                        <div class="row">

                                            <Slider {...settings} ref={slider => {
                                                sliderRef = slider;
                                            }}>


                                                <div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="row">
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C1} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C2} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C3} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C4} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C5} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C5} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C3} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C4} alt="" />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div class="row">
                                                        <div class="col-sm-12">
                                                            <div class="row">
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C1} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C2} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C3} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C4} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C5} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C5} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C3} alt="" />
                                                                    </div>
                                                                </div>
                                                                <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                                    <div class="game_box">
                                                                        <img class="w-100" src={C4} alt="" />
                                                                    </div>
                                                                </div>

                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>



                                                {/* </div> */}

                                                {/* </div> */}





                                            </Slider>

                                           


                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="profile-tab-pane" role="tabpanel" aria-labelledby="profile-tab"
                                        tabindex="0">
                                        <div class="row">

                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C1} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C2} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C3} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C4} alt="" />
                                                        </div>
                                                    </div>

                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C5} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C5} alt="" />
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>


                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="contact-tab-pane" role="tabpanel" aria-labelledby="contact-tab"
                                        tabindex="0">
                                        <div class="row">

                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C1} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C2} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C3} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C4} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C5} alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src={C5} alt="" />
                                                        </div>
                                                    </div>
                                                </div>



                                            </div>


                                        </div>
                                    </div>
                                    <div class="tab-pane fade" id="disabled-tab-pane" role="tabpanel" aria-labelledby="disabled-tab"
                                        tabindex="0">
                                        <div class="row">

                                            <div class="col-sm-12">
                                                <div class="row">
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src="images/c1.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src="images/c2.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src="images/c3.png" alt="" />
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src="images/c4.png" alt="" />
                                                        </div>
                                                    </div>


                                                    <div class="col-sm-6 col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-6">
                                                        <div class="game_box">
                                                            <img class="w-100" src="images/c4.png" alt="" />
                                                        </div>
                                                    </div>

                                                </div>



                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </section >
            {/* Game portfolio start */}
            < section className="game_portfolio_sec" >
                <div className="container">
                    <div className="row">
                        <div className="game_port_inn">
                            <h4>OTHER PROVIDERS</h4>
                            <div className="portfolio">
                                <div className="port_box">
                                    <div className="port_box_brdr wow animate__animated animate__swing">
                                        <img src={Logo} alt />
                                    </div>
                                    <p>Provider Name</p>
                                </div>
                                <div className="port_box">
                                    <div className="port_box_brdr wow animate__animated animate__swing">
                                        <img src={Logo} alt />
                                    </div>
                                    <p>Provider Name</p>
                                </div>
                                <div className="port_box">
                                    <div className="port_box_brdr wow animate__animated animate__swing">
                                        <img src={Logo} alt />
                                    </div>
                                    <p>Provider Name</p>
                                </div>
                                <div className="port_box">
                                    <div className="port_box_brdr wow animate__animated animate__swing">
                                        <img src={Logo} alt />
                                    </div>
                                    <p>Provider Name</p>
                                </div>
                                <div className="port_box">
                                    <div className="port_box_brdr wow animate__animated animate__swing">
                                        <img src={Logo} alt />
                                    </div>
                                    <p>Provider Name</p>
                                </div>
                                <div className="port_box">
                                    <div className="port_box_brdr wow animate__animated animate__swing">
                                        <img src={Logo} alt />
                                    </div>
                                    <p>Provider Name</p>
                                </div>
                                <div className="port_box">
                                    <div className="port_box_brdr wow animate__animated animate__swing">
                                        <img src={Logo} alt />
                                    </div>
                                    <p>Provider Name</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <ContactStart />

        </div >
    )
}

export default GameInner
