/**
 * @file_purpose  page for Service
 * @author Swarnali Ghosh
 * @Date_Created 31-01-2023
 * @Date_Modified 31-01-2023
 */
import React, { useEffect } from "react";
import Support from "../../asset/image/customer-service.png";
import Layer_2 from "../../asset/image/Layer_2.png";
import Testing from "../../asset/image/testing.png";
import Customersupport from "../../asset/image/customer-support-2.png";
const Service = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {/* service start */}
      <section className="our_game_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="ourgame_inn">
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <h4>OUR</h4>
                  <h4 style={{ color: "#017cf3" }}>&nbsp;SERVICES</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="business_sec pt-0">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="business_inn wow animate__animated animate__zoomIn">
                <h4 className="pt-0 pt-sm-4">24x7 - B2B Support</h4>
                <p>
                  We recognize the pivotal role that robust B2B support plays in
                  the success of casino operators and partners. Our commitment
                  to providing unparalleled support is unwavering, ensuring a
                  seamless and efficient experience for your gaming business.
                </p>

                <div class="indepen_side">
                  <div class="row">
                    <div class="col-sm-6">
                      <ul>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Access our
                          dedicated support team round the clock.
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Expert
                          assistance for technical inquiries and
                          troubleshooting.
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Expert advice
                          on incorporating our casino game applications into
                          your platform.
                        </li>
                      </ul>
                    </div>
                    <div class="col-sm-6">
                      <ul>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Swift
                          identification and resolution of potential issues to
                          minimize downtime.
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Regular
                          system health checks to maintain optimal performance.
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Proactive
                          collaboration to enhance your gaming platform
                          continuously.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="white_levelsec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="white_level_inn ">
                <h6>Risk & Fraud Management</h6>
                <p>
                  We prioritize the security and integrity of your gaming
                  operations. Our comprehensive Risk & Fraud Management
                  solutions are meticulously designed to safeguard your casino
                  game application and protect your business from potential
                  threats.
                </p>
                <div className="white_level_inn_dwn">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="white_level_inn_dwn_lt wow animate__animated animate__fadeInLeft">
                        <ul>
                          <li>
                            <i className="fa-solid fa-circle-check" /> Implement
                            industry-leading encryption and data protection
                            measures.
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" /> Ensure
                            the confidentiality and integrity of sensitive
                            information.
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" /> Instant
                            alerts and proactive intervention to mitigate
                            potential risks.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="white_level_inn_dwn_lt wl_pad wow animate__animated animate__fadeInRight">
                        <ul>
                          <li>
                            <i className="fa-solid fa-circle-check" /> Utilize
                            sophisticated algorithms to analyze user behavior
                            and detect anomalies.
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" /> Implement
                            multi-factor authentication to fortify user identity
                            verification.
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />{" "}
                            Strengthen access controls and protect against
                            unauthorized access.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="business_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="business_inn wow animate__animated animate__zoomIn">
                <h4>Development & Deployment</h4>
                <p>
                  We specialize in the seamless development and deployment of
                  cutting-edge casino game applications with Tailored strategies
                  to be aligned with your business objectives.
                </p>

                <div class="indepen_side">
                  <div class="row">
                    <div class="col-sm-6">
                      <ul>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Expert game
                          developers crafting bespoke solutions to meet your
                          specific requirements.
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Diverse game
                          genres and styles to cater to a broad audience.
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Develop
                          applications compatible with various platforms for
                          extended reach.
                        </li>
                      </ul>
                    </div>
                    <div class="col-sm-6">
                      <ul>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Cutting-edge
                          design elements for visually stunning and intuitive
                          interfaces.
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> User-centric
                          approach for an immersive and enjoyable gaming
                          experience.
                        </li>
                        <li>
                          <i class="fa-solid fa-circle-check"></i> Performance
                          testing for smooth gameplay under various conditions.
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="white_levelsec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="white_level_inn ">
                <h6>Customer Services</h6>
                <p>
                  We understand that exceptional customer service is the
                  cornerstone of a successful gaming experience. Dive into our
                  customer-centric approach, where satisfaction is not just a
                  goal but a commitment.
                </p>
                <div className="white_level_inn_dwn">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="white_level_inn_dwn_lt wow animate__animated animate__fadeInLeft">
                        <ul>
                          <li>
                            <i className="fa-solid fa-circle-check" /> Real-time
                            responses to address queries and concerns promptly.
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" /> A diverse
                            team fluent in multiple languages to cater to a
                            global audience.
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />{" "}
                            Assistance in navigating features and maximizing the
                            gaming experience.
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="white_level_inn_dwn_lt wl_pad wow animate__animated animate__fadeInRight">
                        <ul>
                          <li>
                            <i className="fa-solid fa-circle-check" /> Dedicated
                            experts to swiftly identify and resolve any user
                            issues.
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" /> Proactive
                            problem-solving to enhance user trust and loyalty.
                          </li>
                          <li>
                            <i className="fa-solid fa-circle-check" />{" "}
                            Transparent and proactive communication to foster
                            trust.
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className="service-section business_sec">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="service-section-points">
                <div className="service-heading-img-section">
                  <div className="service-heading-img">
                    <img src={Support} alt="" />
                  </div>
                  <div className="service-heading-p-div">
                    <p className="service-p-heading">24x7 - B2B Support</p>
                    <p className="service-p-sub-heading">
                      Experience seamless B2B support around the clock for
                      uninterrupted business excellence
                    </p>
                  </div>
                </div>
                <div className="srv_rt_txt">
                  <p>
                    We recognize the pivotal role that robust B2B support plays
                    in the success of casino operators and partners. Our
                    commitment to providing unparalleled support is unwavering,
                    ensuring a seamless and efficient experience for your gaming
                    business.
                  </p>
                </div>
                <ul className="srv_dwn_panel">
                  <li>Access our dedicated support team round the clock.</li>
                  <li>
                    Expert assistance for technical inquiries and
                    troubleshooting.
                  </li>
                  <li>
                    Expert advice on incorporating our casino game applications
                    into your platform.
                  </li>
                  <li>
                    Swift identification and resolution of potential issues to
                    minimize downtime.
                  </li>
                  <li>
                    Regular system health checks to maintain optimal
                    performance.
                  </li>
                  <li>
                    Proactive collaboration to enhance your gaming platform
                    continuously.
                  </li>
                </ul>
              </div>

              <div className="service-section-points">
                <div className="service-heading-img-section">
                  <div className="service-heading-img">
                    <img src={Layer_2} alt="" />
                  </div>
                  <div className="service-heading-p-div">
                    <p className="service-p-heading">Risk & Fraud Management</p>
                    <p className="service-p-sub-heading">
                      Trust in our robust Risk & Fraud Management solutions for
                      a secure and resilient business environment
                    </p>
                  </div>
                </div>
                <div className="srv_rt_txt">
                  <p>
                    We prioritize the security and integrity of your gaming
                    operations. Our comprehensive Risk & Fraud Management
                    solutions are meticulously designed to safeguard your casino
                    game application and protect your business from potential
                    threats.
                  </p>
                </div>
                <ul className="srv_dwn_panel">
                  <li>
                    Implement industry-leading encryption and data protection
                    measures.
                  </li>
                  <li>
                    Ensure the confidentiality and integrity of sensitive
                    information.
                  </li>
                  <li>
                    Instant alerts and proactive intervention to mitigate
                    potential risks.
                  </li>
                  <li>
                    Utilize sophisticated algorithms to analyze user behavior
                    and detect anomalies.
                  </li>
                  <li>
                    Implement multi-factor authentication to fortify user
                    identity verification.
                  </li>
                  <li>
                    Strengthen access controls and protect against unauthorized
                    access.
                  </li>
                </ul>
              </div>

              <div className="service-section-points">
                <div className="service-heading-img-section">
                  <div className="service-heading-img">
                    <img src={Testing} alt="" />
                  </div>
                  <div className="service-heading-p-div">
                    <p className="service-p-heading">
                      Development & Deployment
                    </p>
                    <p className="service-p-sub-heading">
                      Empower your projects with our highly skilled team,
                      delivering unmatched expertise in development and seamless
                      deployment
                    </p>
                  </div>
                </div>
                <div className="srv_rt_txt">
                  <p>
                    We specialize in the seamless development and deployment of
                    cutting-edge casino game applications with Tailored
                    strategies to be aligned with your business objectives.
                  </p>
                </div>
                <ul className="srv_dwn_panel">
                  <li>
                    Expert game developers crafting bespoke solutions to meet
                    your specific requirements.
                  </li>
                  <li>
                    Diverse game genres and styles to cater to a broad audience.
                  </li>
                  <li>
                    Develop applications compatible with various platforms for
                    extended reach.
                  </li>
                  <li>
                    Cutting-edge design elements for visually stunning and
                    intuitive interfaces.
                  </li>
                  <li>
                    User-centric approach for an immersive and enjoyable gaming
                    experience.
                  </li>
                  <li>
                    Performance testing for smooth gameplay under various
                    conditions.
                  </li>
                </ul>
              </div>

              <div className="service-section-points">
                <div className="service-heading-img-section">
                  <div
                    className="service-heading-img"
                    style={{ marginRight: "15px" }}
                  >
                    <img src={Customersupport} alt="" />
                  </div>
                  <div className="service-heading-p-div">
                    <p className="service-p-heading">Customer Services</p>
                    <p className="service-p-sub-heading">
                      Experience unparalleled customer service, where your
                      satisfaction is our top priority
                    </p>
                  </div>
                </div>
                <div className="srv_rt_txt">
                  <p>
                    We understand that exceptional customer service is the
                    cornerstone of a successful gaming experience. Dive into our
                    customer-centric approach, where satisfaction is not just a
                    goal but a commitment.
                  </p>
                </div>
                <ul className="srv_dwn_panel">
                  <li>
                    Real-time responses to address queries and concerns
                    promptly.
                  </li>
                  <li>
                    A diverse team fluent in multiple languages to cater to a
                    global audience.
                  </li>
                  <li>
                    Assistance in navigating features and maximizing the gaming
                    experience.
                  </li>
                  <li>
                    Dedicated experts to swiftly identify and resolve any user
                    issues.
                  </li>
                  <li>
                    Proactive problem-solving to enhance user trust and loyalty.
                  </li>
                  <li>
                    Transparent and proactive communication to foster trust.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Service;
